import {Icon} from '@shopify/polaris';
import {CartMajor} from '@shopify/polaris-icons';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';

export const AdminNavigation = () => {
  const cartData = useSelector((state) => state.cart.data) || [];

  return (
    <nav className="Nav">
      <div className="Admin__Nav">
        <NavLink to={'/'}>Club Site</NavLink>
        <NavLink to={'/admin'}>Club Owner Requests</NavLink>
        <NavLink to={'/admin/products/'}>Base Products</NavLink>
        <NavLink to={'/admin/stocks/'}>Product Stock</NavLink>
      </div>
    </nav>
  );
};
