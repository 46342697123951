import {CART_SET_DATA, CART_SET_CONFIG} from '../actions/types';

export const initialConfigData = {
  selectedColorsList: [],
  colorsListBasedOnCart: [],
  loading: false
};

export const resetLocalStorage = () => {
  localStorage.setItem('cart-data', '');
  localStorage.setItem('config-data', '');
};

export const initialState = {
  data: localStorage.getItem('cart-data')
    ? JSON.parse(localStorage.getItem('cart-data'))
    : [],
  config: localStorage.getItem('config-data')
    ? JSON.parse(localStorage.getItem('config-data'))
    : initialConfigData
};

const checkIfAllColorsAreSelected = (
  colorsListBasedOnCart,
  selectedColorsList
) => {
  let allSelected = [];
  colorsListBasedOnCart.forEach((cLBOC) => {
    selectedColorsList.forEach((sCL) => {
      if (sCL.cartColorID === cLBOC.color) {
        allSelected.push(true);
      }
    });
  });
  //  console.log(allSelected);
  return colorsListBasedOnCart.length === allSelected.length;
};

const validateClubData = (payload) => {
  return (
    !!payload?.logo?.url &&
    !!checkIfAllColorsAreSelected(
      payload.colorsListBasedOnCart,
      payload.selectedColorsList
    )
  );
};

const finalValidation = (payload) => {
  return (
    !!validateClubData(payload) &&
    !!payload.clubName &&
    !!payload.fullname &&
    !!payload.email &&
    !!payload.phone &&
    !payload.isEmailExists
  );
};

const clubAddNewProductsValidation = (payload) => {
  return payload.colorsListBasedOnCart.length
    ? !!checkIfAllColorsAreSelected(
        payload.colorsListBasedOnCart,
        payload.selectedColorsList
      )
    : true;
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_SET_DATA: {
      localStorage.setItem('cart-data', JSON.stringify(action.payload));
      return {
        ...state,
        data: action.payload
      };
    }
    case CART_SET_CONFIG: {
      let isValidData = false;
      let isClubDataValid = false;
      let isClubAddNewProductsValid = false;
      //      console.log(action.payload);

      if (validateClubData(action.payload)) {
        isClubDataValid = true;
      } else {
        isClubDataValid = false;
      }
      if (finalValidation(action.payload)) {
        isValidData = true;
      } else {
        isValidData = false;
      }
      if (clubAddNewProductsValidation(action.payload)) {
        isClubAddNewProductsValid = true;
      } else {
        isClubAddNewProductsValid = false;
      }
      action.payload = {
        ...action.payload,
        isValidData,
        isClubDataValid,
        isClubAddNewProductsValid
      };
      localStorage.setItem('config-data', JSON.stringify(action.payload));
      return {
        ...state,
        config: action.payload
      };
    }

    default: {
      return {...state};
    }
  }
};
