import {Icon} from '@shopify/polaris';
import {ToggleMinor} from '@shopify/polaris-icons';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {saveFileDataToAPIEndpoint} from '../axios';
import {textColorsList} from '../config';
import {Button} from '../global/common/Button';
import {ImageConfigPreview} from '../global/common/ImageConfigPreview';
import {Loader} from '../global/common/Loader';
import {SelectColor} from '../global/common/SelectColor';
import {setConfigData} from './actions';
import {getProductsDataFromCartData} from './Cart';
import './ClubConfiguration.scss';
import {DefaultImage} from './DefaultImage';
import {
  FILTER_NAMES,
  getColorFromGermanWord,
  getFilter,
  getTag
} from './ProductsUtils';

export const ClubConfiguration = () => {
  const dispatch = useDispatch();
  const [cartData, setCartData] = useState([]);
  const tmpCartData = useSelector((state) => state.cart.data) || [];
  const configData = useSelector((state) => state.cart.config) || [];
  const baseProducts = useSelector((state) => state.products.baseProducts);
  const baseProductsLoading = useSelector(
    (state) => state.products.baseProductsLoading
  );

  useEffect(() => {
    let cLists = [];
    if (!!baseProducts.length) {
      const cartDataList = getProductsDataFromCartData(
        tmpCartData,
        baseProducts
      );
      setCartData(cartDataList);
      cartDataList?.map((cData) => {
        const colorFil = getTag(cData.shopifyProduct, FILTER_NAMES.colors);
        //console.log(cData);

        if (colorFil && !!colorFil?.value && cData.is_customizable) {
          cLists.push({
            id: colorFil.id,
            color: getColorFromGermanWord(colorFil.value),
            label: colorFil.value
          });
        }
      });
      const uniqueColorsData = [
        ...new Map(cLists.map((m) => [m.color, m])).values()
      ];

      dispatch(
        setConfigData({
          ...configData,
          colorsListBasedOnCart: uniqueColorsData
        })
      );
    }
  }, [baseProducts]);

  const setLogo = (data) => {
    //console.log(data);
    dispatch(setConfigData({...configData, logo: null}));
    dispatch(setConfigData({...configData, logo: data}));
  };
  const handleLogoChange = (event) => {
    event.preventDefault();
    // console.log(event.target.files[0]);
    setLogo({
      //  file: event.target.files[0],
      url: URL.createObjectURL(event.target.files[0]),
      filename: event.target.files[0].name
    });
  };
  const onDropFile = (event) => {
    event.preventDefault();
    let dt = event.dataTransfer;
    let files = dt.files;
    setLogo({
      //  file: files[0],
      url: URL.createObjectURL(files[0])
    });
  };

  const updateColorData = (cl, clBasedOnCart) => {
    let finalData = configData.selectedColorsList || [];
    finalData = [
      ...finalData,
      {
        cartColorID: clBasedOnCart.color,
        selectedTextColorID: cl.id
      }
    ];

    const uniqueColorsData = [
      ...new Map(finalData.map((m) => [m.cartColorID, m])).values()
    ];
    dispatch(
      setConfigData({
        ...configData,
        selectedColorsList: uniqueColorsData
      })
    );
  };

  const filterSameColor = (cList, color) =>
    cList.filter((cli) => cli.color !== color);

  const getSelectedColor = (cartColorID) => {
    const selectedColorData = configData?.selectedColorsList?.find(
      (sCL) => sCL.cartColorID === cartColorID
    );
    return textColorsList.find(
      (tCL) => tCL.id === selectedColorData?.selectedTextColorID
    );
  };
  const getFirstColorBasedOnCart = () =>
    configData.colorsListBasedOnCart.length &&
    configData.colorsListBasedOnCart[0];

  const getFirstImageForPreview = () => {
    const color = configData.colorsListBasedOnCart[0].label;

    return cartData.find((c) => {
      const colorFil = getTag(c.shopifyProduct, FILTER_NAMES.colors);

      if (colorFil.value === color && c.is_customizable) {
        return c;
      }
    });
  };

  const getImageForPreview = (key) => {
    const color = configData.colorsListBasedOnCart[key].label;

    return cartData.find((c) => {
      const colorFil = getTag(c.shopifyProduct, FILTER_NAMES.colors);

      if (colorFil.value === color && c.is_customizable) {
        return c;
      }
    });
  };

  if (!!baseProductsLoading) {
    return <Loader />;
  }

  return cartData.length ? (
    <div className="club-config">
      <div className="club-config__left-part">
        <div className="form-field">
          <label htmlFor="ClubLogo">TEAMLOGO HOCHLADEN*</label>
          <div
            className="form-field__logo-preview"
            onDrop={onDropFile}
            onDragOver={(e) => {
              e.preventDefault();
              const ele = document.getElementsByClassName(
                'form-field__logo-preview'
              )[0];
              ele.style.border = '2px solid black';
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              const ele = document.getElementsByClassName(
                'form-field__logo-preview'
              )[0];
              ele.style.border = 'none';
            }}
          >
            {configData.logo?.url ? (
              <img
                className="form-field__logo-preview__image"
                src={configData.logo.url}
                alt={'logo'}
              />
            ) : (
              <>
                <DefaultImage />
                <span className="form-field__logo-preview__text">
                  LOGO REINZIEHEN ODER HOCHLADEN
                </span>
              </>
            )}
          </div>

          <input
            hidden
            type={'file'}
            id="ClubLogo"
            onChange={handleLogoChange}
          />
          <Button
            onClick={() => document.getElementById('ClubLogo').click()}
            className="form-field__logo-button"
            strechcontent
          >
            LOGO HOCHLADEN
          </Button>
          <span className="logohint">
            FORMAT: .SVG ODER .PNG <br />
            EMPFOHLENE GRÖßE:{' '}
          </span>
        </div>
        <div className="form-field">
          <label htmlFor="TextOnBackFirstLine">
            SCHRIFT AUF RÜCKEN ZEILE 1 (OPTIONAL)
          </label>
          <input
            onChange={(e) =>
              dispatch(
                setConfigData({
                  ...configData,
                  isCurved: false,
                  firstLineText: e.target.value
                })
              )
            }
            type={'text'}
            id="TextOnBackFirstLine"
            value={configData.firstLineText}
          />

          <div className="form-field__radio-field cuverd">
            <label className="form-field__radio-field-label">
              SCHRIFT GEBOGEN?:
            </label>
            <div
              className={configData.isCurved ? ' switch yes' : 'switch no'}
              onClick={(e) =>
                dispatch(
                  setConfigData({...configData, isCurved: !configData.isCurved})
                )
              }
            >
              {configData.isCurved ? (
                <>
                  <label>JA</label>
                  <div className="switch__button"></div>
                </>
              ) : (
                <>
                  <div className="switch__button"></div>
                  <label>NEIN</label>
                </>
              )}
            </div>
            {/* <div
              className="form-field__radio-field-block"
              onClick={(e) =>
                dispatch(setConfigData({...configData, isCurved: true}))
              }
            >
              <input
                type={'radio'}
                id="isCurvedYes"
                name="isCurved"
                checked={configData.isCurved}
                onClick={(e) =>
                  dispatch(setConfigData({...configData, isCurved: true}))
                }
              />
              <label htmlFor="isCurvedYes">JA</label>
            </div>
            <div
              className="form-field__radio-field-block"
              onClick={(e) =>
                dispatch(setConfigData({...configData, isCurved: false}))
              }
            >
              <input
                type={'radio'}
                id="isCurvedNo"
                name="isCurved"
                checked={!configData.isCurved}
                onChange={(e) =>
                  dispatch(setConfigData({...configData, isCurved: false}))
                }
              />
              <label htmlFor="isCurvedNo">Nein</label>
            </div> */}
          </div>
        </div>
        <div className="form-field">
          <label htmlFor="TextOnBackSecondLine">
            SCHRIFT AUF RÜCKEN ZEILE 2 (OPTIONAL)
          </label>
          <input
            onChange={(e) =>
              dispatch(
                setConfigData({...configData, secondLineText: e.target.value})
              )
            }
            type={'text'}
            id="TextOnBackSecondLine"
            value={configData.secondLineText}
          />
        </div>
        <div className="form-field">
          <label htmlFor="TextOnBackSecondLine">
            Zusätzliche Anforderungen
          </label>
          <input
            onChange={(e) =>
              dispatch(
                setConfigData({
                  ...configData,
                  additionalRequirenments: e.target.value
                })
              )
            }
            type={'text'}
            id="additionalRequirenments"
            value={configData.additionalRequirenments}
          />
        </div>
      </div>
      {!!configData.colorsListBasedOnCart.length ? (
        <div className="club-config__right-part">
          {configData.colorsListBasedOnCart.map((clBasedOnCart, key) => (
            <div className="club-config__right-part__wrapper">
              <div className="club-config__right-part__header">
                <span className="club-config__right-part__headerLabel">
                  VORSCHAU TEAM SHIRT {clBasedOnCart.label}
                </span>
                <div className="form-field">
                  <label htmlFor="ColorPreview">
                    <span className="ColorPreviewMobile">
                      FARBE DES SCHRIFTZUGS AUF {clBasedOnCart.label} AUSWÄHLEN*
                    </span>
                    <span className="ColorPreviewDesktop">
                      FARBE DES SCHRIFTZUGS AUSWÄHLEN*
                    </span>
                  </label>
                  <SelectColor
                    id={'ColorPreview' + key}
                    colorsList={filterSameColor(
                      textColorsList,
                      clBasedOnCart.color
                    )}
                    selectedColor={getSelectedColor(clBasedOnCart.color)}
                    onColorSelect={(cl) => updateColorData(cl, clBasedOnCart)}
                  />
                </div>
              </div>
              <ImageConfigPreview
                id={'Club-Owner-Config-Preview-' + key}
                thirdImage={
                  getImageForPreview(key)?.shopifyProduct?.images[1].src
                }
                backImage={
                  getImageForPreview(key)?.shopifyProduct?.images[2].src
                }
                position={getImageForPreview(key)?.position}
                logoImage={configData.logo?.url}
                isClubAdmin={false}
                configData={{
                  isClubNameShowInBack: configData.isClubNameShowInBack,
                  firstLineText: configData.firstLineText,
                  isCurved: configData.isCurved,
                  secondLineText: configData.secondLineText,
                  selectedColor: getSelectedColor(clBasedOnCart.color)
                }}
                defaultColor={
                  clBasedOnCart.color.trim().toLowerCase() === 'white'
                    ? 'black'
                    : 'white'
                }
              />
            </div>
          ))}

          {/* {configData.colorsListBasedOnCart.length > 1 && (
            <div className="FurtherColorsSelection">
              <table cellSpacing="12">
                <tbody>
                  <tr>
                    <th>
                      <span>WEITERE FARBEN OBERTEIL*</span>
                    </th>
                    <th>
                      <span>FARBE DES SCHRIFTZUGS AUSWÄHLEN</span>
                    </th>
                  </tr>

                  {configData?.colorsListBasedOnCart?.length &&
                    configData.colorsListBasedOnCart.map(
                      (clBasedOnCart, key) =>
                        key !== 0 && [
                          <tr
                            key={key + 'color_section'}
                            className="color_section"
                          >
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  maxWidth: '120px'
                                }}
                              >
                                {clBasedOnCart.label}
                                <div
                                  style={{
                                    height: '20px',
                                    width: '60px',
                                    backgroundColor: clBasedOnCart.color,
                                    border:
                                      clBasedOnCart.color === 'white'
                                        ? '1px solid black'
                                        : ''
                                  }}
                                ></div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <SelectColor
                                  id={'ColorPreview'}
                                  colorsList={filterSameColor(
                                    textColorsList,
                                    clBasedOnCart.color
                                  )}
                                  selectedColor={getSelectedColor(
                                    clBasedOnCart.color
                                  )}
                                  onColorSelect={(cl) =>
                                    updateColorData(cl, clBasedOnCart)
                                  }
                                />
                              </div>
                            </td>
                          </tr>,
                          <tr
                            key={key + 'image_section'}
                            className="image_section"
                          >
                            <td colSpan={'2'}>
                              <ImageConfigPreview
                                id={'Club-Owner-Config-Preview-'}
                                frontImage={
                                  getImageForPreview(key)?.shopifyProduct
                                    ?.images[0].src
                                }
                                backImage={
                                  getImageForPreview(key)?.shopifyProduct
                                    ?.images[1].src
                                }
                                position={getImageForPreview(key)?.position}
                                logoImage={configData.logo?.url}
                                isClubAdmin={false}
                                configData={{
                                  isClubNameShowInBack:
                                    configData.isClubNameShowInBack,
                                  firstLineText: configData.firstLineText,
                                  isCurved: configData.isCurved,
                                  secondLineText: configData.secondLineText,
                                  selectedColor: getSelectedColor(
                                    clBasedOnCart.color
                                  )
                                }}
                              />
                            </td>
                          </tr>
                        ]
                    )}
                </tbody>
              </table>
            </div>
          )} */}
        </div>
      ) : (
        <div>Sie haben keine individualisierbaren Produkte ausgewählt</div>
      )}
    </div>
  ) : (
    <Link to={'/products'} className="products-button">
      Add Products
    </Link>
  );
};
