import {Route, Routes} from 'react-router-dom';
import {SAClubOwnerRequestsList} from './super-admin/SAClubOwnerRequestsList.js';
import './App.scss';
import {Products} from './club-owner/Products.js';
import {SuperAdminShopifyBaseProducts} from './super-admin/SAShopifyBaseProducts.js';
import {SuperAdminShopifyBaseProductsDetailPage} from './super-admin/SAShopifyBaseProductsDetailPage.js';

import {AppProvider} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import {ClubInfo} from './club-owner/ClubInfo.js';
import {Layout} from './Layout.js';
import {Cart} from './club-owner/Cart.js';
import {AdminLayout} from './AdminLayout.js';
import {SAClubOwnerRequestsDetails} from './super-admin/SAClubOwnerRequestsDetails.js';
import {Login} from './club-owner/Login.js';
import {useEffect} from 'react';
import {fetchDataFromAPIEndpoint} from './axios.js';
import {setCSFRTokenData, setUserProfileData} from './user/actions/index.js';
import {useDispatch, useSelector} from 'react-redux';
import {ClubConfiguration} from './club-owner/ClubConfiguration.js';
import {Success} from './club-owner/Success.js';
import {ProductsDetails} from './club-owner/ProductsDetails.js';
import {Button} from './global/common/Button.js';
import {ClubDashboard} from './club-owner/ClubDashboard.js';
import {ClubAddNewProducts} from './club-owner/ClubAddNewProducts.js';
import {setClubOwnerData} from './club-owner/actions/index.js';
import {SAProductStocks} from './super-admin/SAProductStocks.js';

export const URLList = {
  Home: '/',
  Dashboard: '/dashboard',
  ProductsDetails: '/product-detail/:id',
  Cart: '/cart',
  ClubConfiguration: '/club-configuration',
  ClubAddNewProducts: '/add-new-products-to-club',
  ClubInfo: '/club-info',
  Success: '/success',
  Login: '/login',
  PAPILLONSPORTSWEARWeb: 'https://www.PAPILLON-SPORTSWEAR.COM'
};

const App = () => {
  const dispatch = useDispatch();
  const csrfToken = useSelector((state) => state.user.csrfToken) || '';
  const profileData = useSelector((state) => state.user.profileData);
  useEffect(() => {
    console.log('first time');

    fetchDataFromAPIEndpoint('/user', true)
      .then((res) => {
        if (res.data.token) {
          dispatch(setCSFRTokenData(res.data.token));
        }
        dispatch(setUserProfileData(res.data));
      })
      .catch((err) => {
        console.log(err.response.data.message);
        localStorage.setItem('csrfToken', '');
        dispatch(setCSFRTokenData(''));
        dispatch(setUserProfileData(null));
      });
  }, [csrfToken]);

  useEffect(() => {
    if (!!profileData?.club_id) {
      fetchDataFromAPIEndpoint(
        '/clubDataBasedOnUser/' + profileData.club_id
      ).then((cbRequest) => {
        dispatch(setClubOwnerData(cbRequest.data));
      });
    }
  }, [profileData]);

  return (
    <AppProvider i18n={enTranslations}>
      <div className="App">
        <Routes>
          {/* Admin routes */}
          {!!profileData?.isAdmin && (
            <Route path="/admin" element={<AdminLayout />}>
              <Route
                key={'super-admin'}
                index
                element={<SAClubOwnerRequestsList />}
              />
              <Route
                key={'super-admin-club-owner-requests'}
                path={'/admin/club-owner-requests/:id'}
                element={<SAClubOwnerRequestsDetails />}
              />
              <Route
                key={'super-admin-products'}
                path="/admin/products"
                element={<SuperAdminShopifyBaseProducts />}
              />
              <Route
                key={'super-admin-products-single'}
                path="/admin/products/:id"
                element={<SuperAdminShopifyBaseProductsDetailPage />}
              />
              <Route
                key={'super-admin-stocks'}
                path="/admin/stocks"
                element={<SAProductStocks />}
              />
            </Route>
          )}
          {/* Club Owner routes */}
          <Route path={URLList.Home} element={<Layout />}>
            {!!profileData?.email && (
              <Route
                key={'club-owner-product-details'}
                path={URLList.Dashboard}
                element={<ClubDashboard />}
              />
            )}
            <Route index key={'club-owner-products'} element={<Products />} />

            <Route
              key={'club-owner-product-details'}
              path={URLList.ProductsDetails}
              element={<ProductsDetails />}
            />
            <Route
              key={'club-owner-cart'}
              path={URLList.Cart}
              element={<Cart />}
            />
            {!!profileData?.club_id ? (
              <>
                <Route
                  key={'club-add-new-products'}
                  path={URLList.ClubAddNewProducts}
                  element={<ClubAddNewProducts />}
                />
              </>
            ) : (
              <>
                <Route
                  key={'club-configuration'}
                  path={URLList.ClubConfiguration}
                  element={<ClubConfiguration />}
                />
                <Route
                  key={'club-info'}
                  path={URLList.ClubInfo}
                  element={<ClubInfo />}
                />
              </>
            )}

            <Route
              key={'success'}
              path={URLList.Success}
              element={<Success />}
            />

            <Route key={'login'} path={URLList.Login} element={<Login />} />
          </Route>
          <Route
            path="*"
            element={
              <div className="NotFound">
                Seite nicht gefunden
                <Button onClick={() => window.location.replace('/')}>
                  Home
                </Button>
              </div>
            }
          />
        </Routes>
      </div>
    </AppProvider>
  );
};

export default App;
