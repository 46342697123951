export const REACT_APP_BE_URL =
  process.env.REACT_APP_BE_URL ?? 'http://5.45.108.33:8080/';
export const ClubStatusOptions = {
  Requested: 'requested',
  Approved: 'approved',
  Rejected: 'rejected',
  ReRequested: 'reRequested'
};

export const textColorsList = [
  {id: 1, color: 'red', label: 'ROT'},
  {id: 2, color: 'blue', label: 'BLAU'},
  {id: 3, color: 'green', label: 'GRÜN'},
  {id: 4, color: 'white', label: 'WEIß'},
  {id: 5, color: 'black', label: 'SCHWARZ'},
  {id: 6, color: 'silver', label: 'SILBER'}
];

export const productColorMapping = [
  {
    germanWord: 'Weiß',
    color: 'white'
  },
  {
    germanWord: 'Schwarz',
    color: 'black'
  },
  {
    germanWord: 'Rot',
    color: 'red'
  },
  {
    germanWord: 'Orange',
    color: 'orange'
  },
  {
    germanWord: 'Gelb',
    color: 'yellow'
  },
  {
    germanWord: 'Grün',
    color: 'green'
  },
  {
    germanWord: 'blau',
    color: 'blue'
  },
  {
    germanWord: 'Lila',
    color: 'purple'
  }
];
// when adding new color, add it here as well frontend/src/global/common/Button.scss
