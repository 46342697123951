import {useEffect, useState} from 'react';
import {deleteDataFromAPIEndpoint, fetchDataFromAPIEndpoint} from '../axios';
import '@shopify/polaris/build/esm/styles.css';
import {Page, Card, Button, Text} from '@shopify/polaris';
import {LogoPreview} from '../global/LogoPreview';
import './SAClubOwnerRequestsList.scss';
import {Link} from 'react-router-dom';
import {REACT_APP_BE_URL} from '../config';

export const SAClubOwnerRequestsList = () => {
  const [clubOwnerRequests, setClubOwnerRequests] = useState([]);
  const [approvedClubOwnerRequests, setApprovedClubOwnerRequests] = useState(
    []
  );

  const setClubReqData = (cbRequest) => {
    const clubData = [];
    const approvedClubData = [];
    cbRequest.data.forEach((d) => {
      console.log(d);
      if (d.clubStatus === 'approved') {
        approvedClubData.push(d);
      } else {
        clubData.push(d);
      }
    });
    setClubOwnerRequests(clubData);
    setApprovedClubOwnerRequests(approvedClubData);
  };

  useEffect(() => {
    fetchDataFromAPIEndpoint('/clubOwnerRequests').then((cbRequest) => {
      console.log(cbRequest.data);
      setClubReqData(cbRequest);
    });
  }, []);

  const ClubOwnerReq = ({data}) => {
    const onDeleteClubRequest = async (id) => {
      let text =
        'Are you sure you want to delete club request ? \nEither OK or Cancel.';
      if (window.confirm(text) === true) {
        const data = await deleteDataFromAPIEndpoint(
          '/clubOwnerRequests/' + id
        );
        setClubReqData(data);
      }
    };
    return (
      <div className="club-owner-requests__wrapper">
        {data?.map((c, key) => (
          <Card sectioned key={key}>
            <div className="club-owner-requests__item">
              <div className="club-owner-requests__item_left_part">
                <LogoPreview url={REACT_APP_BE_URL + c.clubLogo} />

                <div className="club-owner-requests__item_info">
                  <div>
                    <p> Team Name:</p>
                    <b> {c.clubName}</b>
                  </div>
                  <div>
                    <p> Email:</p>
                    <b> {c.email}</b>
                  </div>
                  <div>
                    <p> Club Status:</p>
                    <b> {c.clubStatus}</b>
                  </div>
                </div>
              </div>
              <div>
                <Link to={'/admin/club-owner-requests/' + c._id}>
                  <Button>View</Button>
                </Link>
                &nbsp;&nbsp;
                <Button onClick={() => onDeleteClubRequest(c._id)}>
                  Delete
                </Button>
              </div>
            </div>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <Page title="Club Owner Requests">
      {!clubOwnerRequests.length && !approvedClubOwnerRequests.length ? (
        <div>keine Clubanfragen </div>
      ) : (
        <>
          <h2 className="SA__heading">Neue Anfragen</h2>
          {!clubOwnerRequests.length && <div>keine Neue Anfragen </div>}
          <ClubOwnerReq data={clubOwnerRequests} />

          <h2 className="SA__heading">Bestätigte Anfragen</h2>
          {!approvedClubOwnerRequests.length && (
            <div>keine Bestätigte Anfragen </div>
          )}
          <ClubOwnerReq data={approvedClubOwnerRequests} />
        </>
      )}
    </Page>
  );
};
