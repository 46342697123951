import {Button, Text} from '@shopify/polaris';
import CircleType from 'circletype';
import html2canvas from 'html2canvas';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {updateDataToAPIEndpoint} from '../axios';
import {downloadBase64File} from '../super-admin/SAClubOwnerRequestsDetails';
import {SpinnerInternal} from './common/Loader';
import './ImageLogoAndTextPosition.scss';

import defaultLogo from './../preview-logo.png';

var circleTypeObject = '';
const frontImageWrapperId = 'firstImageFront';
const frontImageId = 'firstImageFrontImage';
const frontImageLogoWrapperId = 'firstImageFrontLogo';
const frontImageLogoId = 'logoImagePNG';

const secondImageWrapperId = 'secondImageBack';
const secondImageId = 'secondImageBackImage';
const secondImageTextId = 'secondImageBackText';
const secondImageFirstLineTextId = 'firstline';
const secondImageSecondLineTextId = 'secondline';

const thirdImageWrapperId = 'thirdImageFront';
const thirdImageId = 'thirdImageFrontImage';
const thirdImageLogoWrapperId = 'thirdImageFrontLogo';
const thirdImageLogoId = 'thirdlogoImagePNG';

const fourthImageWrapperId = 'fourthImageFront';
const fourthImageId = 'fourthImageFrontImage';
const fourthImageLogoWrapperId = 'fourthImageFrontLogo';
const fourthImageLogoId = 'fourthlogoImagePNG';

export const getScreenShot = (ele) => {
  console.log(ele);
  let c = document.getElementById(ele); // or document.getElementById('canvas');
  html2canvas(c, {
    logging: true,
    letterRendering: 1,
    //      allowTaint: true,,
    useCORS: true
    // onrendered: function (canvas) {
    //   var t = canvas.toDataURL().replace('data:image/png;base64,', '');
    //   downloadBase64File('image/png', t, 'image');
    // }
  }).then((canvas) => {
    var t = canvas.toDataURL().replace('data:image/png;base64,', '');
    console.log(t);
    downloadBase64File('image/png', t, 'image');
  });
};

export const calculateFontSize = (fontSize, text) => {
  fontSize = Number(fontSize);
  let finalSize = fontSize;

  const totalChar = text?.length || 0;
  //above 15 char
  if (totalChar > 15) {
    finalSize = fontSize - fontSize * 0.3;
  }
  //between 11 to 15 char
  else if (totalChar <= 15 && totalChar > 10) {
    finalSize = fontSize - fontSize * 0.2;
  }
  //between 6 to 10 char
  else if (totalChar <= 10 && totalChar > 5) {
    finalSize = fontSize - fontSize * 0.1;
  }
  //between 1 to 5 char
  else {
    finalSize = fontSize;
  }
  return finalSize;
};

export const getLogoStyles = (pos, no) => {
  return no === 1
    ? {
        top: pos?.LogoTop || 0,
        left: pos?.LogoLeft || 0
      }
    : no === 3
    ? {
        top: pos?.LogoThirdImageTop || 0,
        left: pos?.LogoThirdImageLeft || 0
      }
    : {
        top: pos?.LogoFourthImageTop || 0,
        left: pos?.LogoFourthImageLeft || 0
      };
};

// Show Super admin site so Admin can set the postion for logo and text
export const ImageLogoAndTextPosition = ({
  frontImage,
  backImage,
  thirdImage,
  forthImage,
  logoImage,
  isClubAdmin,
  position = null,
  id
}) => {
  const params = useParams();
  const [isCurved, setIsCurved] = useState(false);
  const [loading, setLoading] = useState(false);

  const [spaceBetweenText, setSpaceBetweenText] = useState(
    position?.SpaceBetweenText || 0
  );
  const [spaceBetweenCurvedText, setSpaceBetweenCurvedText] = useState(
    position?.SpaceBetweenCurvedText || 0
  );
  const [textSize, setTextSize] = useState(position?.FirslineTextSize || 0);

  const [textBoxWidth, setTextBoxWidth] = useState(position?.TextBoxWidth || 0);
  const [logoSize, setLogoSize] = useState(position?.LogoSize || 100);
  const [logoThirdImageSize, setLogoThirdImageSize] = useState(
    position?.LogoThirdImageSize || 100
  );
  const [logoFourthImageSize, setLogoFourthImageSize] = useState(
    position?.LogoFourthImageSize || 100
  );

  const [firstlineText, setFirstlineText] = useState('Blau-Weiss');
  const [secondlineText, setSecondlineText] = useState('Remscheid');
  const [colorForPreview, setColorForPreview] = useState('#808080');

  const saveLogoAndTextPos = async () => {
    setLoading(true);
    const logoPos = document.getElementById(frontImageLogoWrapperId);
    const logoThirdPos = document.getElementById(thirdImageLogoWrapperId);
    const logoFourthPos = document.getElementById(fourthImageLogoWrapperId);
    const textPos = document.getElementById(secondImageTextId);

    const logoPosStyle = window.getComputedStyle(logoPos);
    const logoThirdPosStyle = window.getComputedStyle(logoThirdPos);
    const logoFourthPosStyle = window.getComputedStyle(logoFourthPos);
    const textPosStyle = window.getComputedStyle(textPos);

    const position = {
      LogoTop: Number(logoPosStyle.getPropertyValue('top').replace('px', '')),
      LogoLeft: Number(logoPosStyle.getPropertyValue('left').replace('px', '')),
      LogoSize: Number(logoSize),

      LogoThirdImageTop: Number(
        logoThirdPosStyle.getPropertyValue('top').replace('px', '')
      ),
      LogoThirdImageLeft: Number(
        logoThirdPosStyle.getPropertyValue('left').replace('px', '')
      ),
      LogoThirdImageSize: Number(logoThirdImageSize),

      LogoFourthImageTop: Number(
        logoFourthPosStyle.getPropertyValue('top').replace('px', '')
      ),
      LogoFourthImageLeft: Number(
        logoFourthPosStyle.getPropertyValue('left').replace('px', '')
      ),
      LogoFourthImageSize: Number(logoFourthImageSize),

      TextTop: Number(textPosStyle.getPropertyValue('top').replace('px', '')),
      TextLeft: Number(textPosStyle.getPropertyValue('left').replace('px', '')),
      SpaceBetweenText: Number(spaceBetweenText),
      SpaceBetweenCurvedText: Number(spaceBetweenCurvedText),
      FirslineTextSize: Number(textSize),
      SecondLineTextSize: Number(textSize),
      TextBoxWidth: Number(textBoxWidth)
    };

    const res = await updateDataToAPIEndpoint(
      'updateBaseProductData/' + params.id,
      {
        position
      }
    );
    setLoading(false);
    console.log(res);
  };

  const dragElement = (elmnt, conatainer) => {
    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    const dragMouseDown = (e) => {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    };
    const theTextEle = elmnt;
    if (theTextEle) {
      /* if present, the header is where you move the DIV from:*/
      theTextEle.onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }

    const elementDrag = (e) => {
      // var offsetX = theTextEle
      //   ? e.clientX - parseInt(window.getComputedStyle(theTextEle).left)
      //   : 0;
      // var offsetY = theTextEle
      //   ? e.clientY - parseInt(window.getComputedStyle(theTextEle).top)
      //   : 0;
      // const draw = document.getElementById('mainContainer');
      // if (!draw) return false;
      // e = e || window.event;
      // e.preventDefault();
      // let top = e.clientY - offsetY;
      // let left = e.clientX - offsetX;

      // if (top < 0) top = 0;
      // if (left < 0) left = 0;
      // if (top > draw.clientHeight - elmnt.clientHeight)
      //   top = draw.clientHeight - elmnt.clientHeight;
      // if (left > draw.clientWidth - elmnt.clientWidth)
      //   left = draw.clientWidth - elmnt.clientWidth;

      // elmnt.style.top = top + 'px';
      // elmnt.style.left = left + 'px';
      // return true;
      // const arrow = Arrow();
      // const svg = draw.querySelector('svg');

      // draw.insertAdjacentHTML('afterbegin', arrow);
      // svg && svg.remove();

      // calculate the new cursor position:
      let pOffsetBottom =
        elmnt.parentNode.offsetTop + elmnt.parentNode.offsetHeight - 1;
      let offsetRight =
        elmnt.parentNode.offsetLeft + elmnt.parentNode.offsetWidth - 1;
      //console.log(pOffsetBottom, offsetRight);

      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      console.log(pos1, pos2, pos3, pos4);
      // pos3 = pos3 > pOffsetBottom ? pOffsetBottom : pos3;
      // pos3 = pos3 > offsetRight ? offsetRight : pos3;
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
      elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';

      const draw = document.getElementById(conatainer);

      if (draw) {
        var offsetX = e.clientX - parseInt(window.getComputedStyle(elmnt).left);
        var offsetY = e.clientY - parseInt(window.getComputedStyle(elmnt).top);

        let top = e.clientY - offsetY;
        let left = e.clientX - offsetX;

        if (top < 0) top = 0;
        if (left < 0) left = 0;
        if (top > draw.clientHeight - elmnt.clientHeight)
          top = draw.clientHeight - elmnt.clientHeight;
        if (left > draw.clientWidth - elmnt.clientWidth)
          left = draw.clientWidth - elmnt.clientWidth;

        elmnt.style.top = top + 'px';
        elmnt.style.left = left + 'px';
      }
    };

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    }
  };

  useEffect(() => {
    if (!isClubAdmin) {
      dragElement(
        document.getElementById(frontImageLogoWrapperId),
        frontImageWrapperId
      );
      dragElement(
        document.getElementById(secondImageTextId),
        secondImageWrapperId
      );
      dragElement(
        document.getElementById(thirdImageLogoWrapperId),
        thirdImageWrapperId
      );
      dragElement(
        document.getElementById(fourthImageLogoWrapperId),
        fourthImageWrapperId
      );
    }
  }, []);

  const onTextChangeFirstline = async (e) => {
    console.log(e.target.value);
    setFirstlineText(e.target.value);
    var div = document.getElementById(secondImageFirstLineTextId);
    div.innerHTML = e.target.value || 'firstline';
    setIsCurved(false);
  };
  const onTextChangeSecondline = (e) => {
    console.log(e.target.value);
    setSecondlineText(e.target.value);

    var div = document.getElementById(secondImageSecondLineTextId);
    div.innerHTML = e.target.value || 'secondline';
  };
  const onTextColorChange = (e) => {
    console.log(e.target.value);
    document.getElementById(secondImageFirstLineTextId).style.color =
      e.target.value;
    document.getElementById(secondImageSecondLineTextId).style.color =
      e.target.value;
    setColorForPreview(e.target.value);
  };

  const onCurved = async (checked) => {
    console.log(checked);
    if (checked) {
      setIsCurved(true);
      if (circleTypeObject) circleTypeObject?.destroy();
      var div = document.getElementById(secondImageFirstLineTextId);
      div.innerHTML = firstlineText || 'firstline';
      circleTypeObject = await new CircleType(div);

      // // Set the text radius and direction. Note: setter methods are chainable.
      circleTypeObject.radius(90);
      // circleTypeObject.refresh();
      circleTypeObject.forceHeight(false);
      circleTypeObject.forceWidth(false);
      // circleTypeObject.radius(90);
      // window.addEventListener('resize', () => {
      //   demo4.radius(demo4.element.offsetWidth / 2);
      // });
      //      updateRadius();
    } else {
      setIsCurved(false);
      circleTypeObject.destroy();
    }
  };

  const getTextStyles = (pos) => {
    return {
      top: pos?.TextTop || 0,
      left: pos?.TextLeft || 0,
      width: textBoxWidth + 'px'
    };
  };
  const fontSize = calculateFontSize(
    textSize,
    document.getElementById(secondImageFirstLineTextId)?.innerText
  );
  const logoSrc = logoImage || defaultLogo;
  return (
    <div className="ImageLogoAndTextPosition">
      <div className="logo-options">
        <Text variant="headingLg" as="h2">
          Set Logo Position (First Image)
        </Text>
        <div className="product-detail-main-wrapper">
          <div className="product-detail-main" id={frontImageWrapperId}>
            <img
              src={frontImage}
              id={frontImageId}
              className="firstImageFrontImage"
              alt=""
              crossOrigin="anonymous"
              height={500}
            />

            <div
              id={frontImageLogoWrapperId}
              className="product-detail-text"
              // onMouseDown='this.style.border = "dashed 2px #FF7F27";'
              style={getLogoStyles(position, 1)}
            >
              <img
                id={frontImageLogoId}
                alt="test"
                width={logoSize || 50}
                src={logoSrc}
              />
            </div>
          </div>
          <div>
            <Text variant="headingXl">
              <b>Please enter data</b>
            </Text>
            <div className="text-options-form">
              <label htmlFor="logo-input-width">Logo size </label>
              <input
                onChange={(e) => setLogoSize(e.target.value)}
                type="number"
                id="logo-input-width"
                value={logoSize}
                key={'logo-size'}
              />
            </div>
            <Button onClick={() => getScreenShot(frontImageWrapperId)}>
              Download firstImageFront sample
            </Button>
          </div>
        </div>
      </div>

      {/* second image */}
      <div className="logo-options">
        <Text variant="headingLg" as="h2">
          Set Logo Position (Second Image)
        </Text>
        <div className="product-detail-main-wrapper">
          <div className="product-detail-main" id={thirdImageWrapperId}>
            <img
              src={thirdImage}
              id={thirdImageId}
              className="firstImageFrontImage"
              alt=""
              crossOrigin="anonymous"
              height={500}
            />

            <div
              id={thirdImageLogoWrapperId}
              className="product-detail-text"
              // onMouseDown='this.style.border = "dashed 2px #FF7F27";'
              style={getLogoStyles(position, 3)}
            >
              <img
                id={thirdImageLogoId}
                alt="test"
                width={logoThirdImageSize || 50}
                src={logoSrc}
              />
            </div>
          </div>
          <div>
            <Text variant="headingXl">
              <b>Please enter data</b>
            </Text>
            <div className="text-options-form">
              <label htmlFor="logo-input-width">Logo size </label>
              <input
                onChange={(e) => setLogoThirdImageSize(e.target.value)}
                type="number"
                id="logo-input-width"
                value={logoThirdImageSize}
                key={'logo-size'}
              />
            </div>
            <Button onClick={() => getScreenShot(frontImageWrapperId)}>
              Download firstImageFront sample
            </Button>
          </div>
        </div>
      </div>

      <div className="text-options">
        <Text variant="headingLg" as="h2">
          Set Text Position (Third Image)
        </Text>
        <div className="text-options__top-section">
          <div className="text-options__top-left-section">
            <div className="text-options-form">
              <h2>
                <b>Only for Testing</b>
              </h2>
              <div>
                <label htmlFor="text-input-box">Firstline text</label>
                <input
                  onChange={onTextChangeFirstline}
                  type="text"
                  id="text-input-box"
                  value={firstlineText}
                />
              </div>
              <div>
                <label htmlFor="text-input-box-2">Secondline text</label>
                <input
                  onChange={onTextChangeSecondline}
                  type="text"
                  id="text-input-box-2"
                  value={secondlineText}
                />
              </div>
              <div className="checkbox">
                <input
                  onClick={(e) => onCurved(e.target.checked)}
                  type="checkbox"
                  checked={isCurved}
                  id="text-input-curved"
                />
                <label htmlFor="text-input-curved">Curved 1st row </label>
              </div>
              <div>
                <label htmlFor="text-input-color">Font color </label>
                <input
                  onChange={onTextColorChange}
                  type="color"
                  id="text-input-color"
                  value={colorForPreview}
                />
              </div>
              <br />
            </div>
            <div className="product-detail-main-wrapper">
              <div className="product-detail-main" id={secondImageWrapperId}>
                <img
                  src={backImage}
                  id={secondImageId}
                  className="secondImageBackImage"
                  alt=""
                  crossOrigin="anonymous"
                />

                <div
                  id={secondImageTextId}
                  className="product-detail-text"
                  // onMouseDown='this.style.border = "dashed 2px #FF7F27";'
                  style={getTextStyles(position)}
                >
                  <span
                    id={secondImageFirstLineTextId}
                    className="firstline"
                    style={{
                      fontSize: fontSize + 'px',
                      color: colorForPreview
                    }}
                  >
                    {firstlineText}
                  </span>
                  <br />
                  <div
                    id={secondImageSecondLineTextId}
                    className="secondline"
                    style={{
                      marginTop: isCurved
                        ? spaceBetweenCurvedText + 'px'
                        : spaceBetweenText + 'px',
                      fontSize: fontSize + 'px',
                      color: colorForPreview
                    }}
                  >
                    {secondlineText}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-options__top-right-section">
            <div className="text-options-form">
              <Text variant="headingXl">
                <b>Please enter data</b>
              </Text>
              <div>
                <Text variant="headingLg">
                  <b>Spaces between firstline and secondline</b>
                </Text>
                <label htmlFor="text-input-space">Normal Text</label>
                <input
                  onChange={(e) => setSpaceBetweenText(e.target.value)}
                  type="number"
                  id="space-between-text"
                  value={spaceBetweenText}
                />
              </div>
              <div>
                <label htmlFor="text-input-space">Curved Text</label>
                <input
                  onChange={(e) => setSpaceBetweenCurvedText(e.target.value)}
                  type="number"
                  id="space-between-text"
                  value={spaceBetweenCurvedText}
                />
              </div>
              <div>
                <Text variant="headingLg">
                  <b>Font Size</b>
                </Text>
                <label htmlFor="text-input-size-firstline">Text Size</label>
                <input
                  onChange={(e) => setTextSize(e.target.value)}
                  type="number"
                  id="text-input-size-firstline"
                  value={textSize}
                />
              </div>

              <Text variant="bodySm">
                Above font sizes are for 1-5 character. <br />
                For 6 to 10 character size is reduced by 10% <br />
                and 11 to 15 character 20%.
              </Text>
              <br />
              <div>
                <Text variant="headingLg">
                  <b>Box Size</b>
                </Text>
                <label htmlFor="text-input-width">Max width of box</label>
                <Text variant="bodySm">so that text stays inside box</Text>
                <input
                  onChange={(e) => setTextBoxWidth(e.target.value)}
                  type="number"
                  id="text-input-width"
                  value={textBoxWidth}
                />
              </div>

              <Button onClick={() => getScreenShot(secondImageWrapperId)}>
                Download secondImageBack sample
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* fourth image */}
      <div className="logo-options">
        <Text variant="headingLg" as="h2">
          Set Logo Position (Forth Image)
        </Text>
        <div className="product-detail-main-wrapper">
          <div className="product-detail-main" id={fourthImageWrapperId}>
            <img
              src={forthImage}
              id={fourthImageId}
              className="firstImageFrontImage"
              alt=""
              crossOrigin="anonymous"
              height={500}
            />

            <div
              id={fourthImageLogoWrapperId}
              className="product-detail-text"
              // onMouseDown='this.style.border = "dashed 2px #FF7F27";'
              style={getLogoStyles(position, 4)}
            >
              <img
                id={fourthImageLogoId}
                alt="test"
                width={logoFourthImageSize || 50}
                src={logoSrc}
              />
            </div>
          </div>
          <div>
            <Text variant="headingXl">
              <b>Please enter data</b>
            </Text>
            <div className="text-options-form">
              <label htmlFor="logo-input-width">Logo size </label>
              <input
                onChange={(e) => setLogoFourthImageSize(e.target.value)}
                type="number"
                id="logo-input-width"
                value={logoFourthImageSize}
                key={'logo-size'}
              />
            </div>
            <Button onClick={() => getScreenShot(frontImageWrapperId)}>
              Download firstImageFront sample
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        <SpinnerInternal />
      ) : (
        <div>
          <br />
          <br />
          <Button onClick={saveLogoAndTextPos}>Save Logo and Text Data</Button>
        </div>
      )}
    </div>
  );
};
