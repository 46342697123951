import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import './SubHeader.scss';

import {Button, ButtonType} from './common/Button';
import React, {useState} from 'react';
import {URLList} from '../App';
import {onSaveClub} from '../club-owner/ClubInfo';
import {onClubAddNewProductSave} from '../club-owner/ClubAddNewProducts';
import {Sidebar} from './common/Sidebar';

const FixedWidthWrapper = ({width, mobileWidth, children}) => {
  return (
    <div>
      <div className="desktop" style={{width: width}}>
        {children}
      </div>
      <div className="mobile" style={{width: mobileWidth}}>
        {children}
      </div>
    </div>
  );
};
export const SubHeader = () => {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.data);
  const configData = useSelector((state) => state.cart.config);
  let navigate = useNavigate();
  const profileData = useSelector((state) => state.user.profileData);

  const location = useLocation();
  const params = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const renderActionButtonContent = React.useCallback(() => {
    const handleOnConfirm = async () => {
      dispatch(onSaveClub());
    };
    const handleOnAddNewProducts = async () => {
      dispatch(onClubAddNewProductSave());
    };
    //console.log(configData.isClubDataValid);
    switch (location.pathname) {
      case URLList.Home:
        return (
          <nav className="SubHeader products">
            <div className="desktop"></div>
            <div className="selected_products">
              ARTIKELAUSWAHL - &shy; <span>{cartData.length}</span> PRODUKTE
              AUSGEWÄHLT
            </div>
            <div className="SubHeader__Actions products">
              <Button
                onClick={() => setIsSidebarOpen(true)}
                type={ButtonType.Secondary}
                minWidth="138px"
                className="desktop"
              >
                HILFE
              </Button>
              <FixedWidthWrapper width={'256px'} mobileWidth={'256px'}>
                <Button
                  disabled={!cartData.length}
                  onClick={() => navigate(URLList.Cart)}
                  strechcontent
                >
                  AUSWAHL BESTÄTIGEN
                </Button>
              </FixedWidthWrapper>
            </div>
          </nav>
        );
      case URLList.ProductsDetails.replace(':id', params.id):
        return (
          <nav className="SubHeader product_detail_header">
            <div>
              <Button onClick={() => navigate('/')}>
                ZURÜCK ZU ARTIKELAUSWAHL
              </Button>
            </div>
            <div className="SubHeader__Actions"></div>
          </nav>
        );

      case URLList.Cart:
        return (
          <nav className="SubHeader">
            <Button
              onClick={() => navigate(URLList.Home)}
              type={ButtonType.Secondary}
              className="desktop"
            >
              <span> ZURÜCK ZUR ARTIKELAUSWAHL</span>
            </Button>
            <div className="selected_products">
              AUSWAHLÜBERSICHT - <span>{cartData.length}</span> PRODUKTE
              AUSGEWÄHLT
            </div>
            <div className="SubHeader__Actions">
              <Button
                onClick={() => setIsSidebarOpen(true)}
                type={ButtonType.Secondary}
                minWidth="138px"
                className="desktop"
              >
                HILFE
              </Button>
              <Button
                onClick={() => navigate(URLList.Home)}
                type={ButtonType.Secondary}
                className="mobile"
                width="131px"
              >
                <span> ZURÜCK</span>
              </Button>
              <FixedWidthWrapper width={'256px'} mobileWidth={'168px'}>
                <Button
                  disabled={!cartData.length}
                  onClick={() =>
                    navigate(
                      !!profileData?.club_id
                        ? URLList.ClubAddNewProducts
                        : URLList.ClubConfiguration
                    )
                  }
                  strechcontent
                >
                  AUSWAHL BESTÄTIGEN
                </Button>
              </FixedWidthWrapper>
            </div>
          </nav>
        );

      case URLList.ClubAddNewProducts:
        return (
          <nav className="SubHeader">
            <Button
              onClick={() => navigate(URLList.Cart)}
              type={ButtonType.Secondary}
            >
              <span className="desktop"> ZURÜCK ZUR ÜBERSICHT</span>
              <span className="mobile"> ZURÜCK</span>
            </Button>
            <div>PRODUKTE ZUM CLUB HINZUFÜGEN</div>
            <div className="SubHeader__Actions">
              <Button
                disabled={!cartData.length}
                onClick={() => setIsSidebarOpen(true)}
                type={ButtonType.Secondary}
                minWidth="138px"
                className="desktop"
              >
                HILFE
              </Button>
              <Button
                disabled={!configData.isClubAddNewProductsValid}
                onClick={handleOnAddNewProducts}
                minWidth="256px"
              >
                BESTÄTIGEN
              </Button>
            </div>
          </nav>
        );

      case URLList.ClubConfiguration:
        return (
          <nav className="SubHeader ClubConfig">
            <Button
              onClick={() => navigate(URLList.Cart)}
              type={ButtonType.Secondary}
              className="desktop"
            >
              <span> ZURÜCK ZUR ÜBERSICHT</span>
            </Button>
            <div>LOGOKONFIGURATION</div>

            <div className="SubHeader__Actions">
              <Button
                disabled={!cartData.length}
                onClick={() => setIsSidebarOpen(true)}
                type={ButtonType.Secondary}
                minWidth="138px"
                className="desktop"
              >
                HILFE
              </Button>
              <Button
                onClick={() => navigate(URLList.Cart)}
                type={ButtonType.Secondary}
                className="mobile"
                strechcontent
                width="140px"
              >
                <span> ZURÜCK</span>
              </Button>
              <FixedWidthWrapper width={'256px'} mobileWidth={'180px'}>
                <Button
                  disabled={!configData.isClubDataValid}
                  onClick={() => navigate(URLList.ClubInfo)}
                  strechcontent
                >
                  BESTÄTIGEN
                </Button>
              </FixedWidthWrapper>
            </div>
          </nav>
        );
      case URLList.ClubInfo:
        return (
          <nav className="SubHeader">
            <Button
              onClick={() => navigate(URLList.ClubConfiguration)}
              type={ButtonType.Secondary}
              className="desktop"
            >
              <span> ZURÜCK ZUR CLUB KONFIGURATION</span>
            </Button>
            <div>KONTAKTDATEN</div>
            <div className="SubHeader__Actions">
              <Button
                onClick={() => navigate(URLList.ClubConfiguration)}
                type={ButtonType.Secondary}
                className="mobile"
                width="140px"
              >
                <span> ZURÜCK</span>
              </Button>
              <FixedWidthWrapper width={'256px'} mobileWidth={'200px'}>
                <Button
                  disabled={!configData.isValidData}
                  onClick={handleOnConfirm}
                  strechcontent
                >
                  BESTÄTIGEN
                </Button>
              </FixedWidthWrapper>
            </div>
          </nav>
        );

      case URLList.Success:
        return (
          <nav className="SubHeader ">
            <div style={{textAlign: 'start'}}>BESTÄTIGUNG</div>
            <div className="SubHeader__Actions success">
              <Button
                onClick={() =>
                  window.open(URLList.PAPILLONSPORTSWEARWeb, '_blank')
                }
              >
                ZURÜCK ZU PAPILLON-SPORTSWEAR.COM
              </Button>
            </div>
            <div></div>
          </nav>
        );

      default:
        return null;
    }
  }, [location.pathname, cartData, configData, navigate, params.id]);

  return (
    <>
      {renderActionButtonContent()}
      {isSidebarOpen && (
        <Sidebar
          onClose={() => setIsSidebarOpen(false)}
          content={
            <div>
              <h2 style={{paddingBottom: '38px'}}>
                HILFE BEI DER ARTIKELAUSWAHL
              </h2>
              <p>
                In dieser Ansicht wählst Du die Artikel für deine Kollektion
                aus.
              </p>
            </div>
          }
        ></Sidebar>
      )}
    </>
  );
};
