import {useCallback, useEffect, useState} from 'react';
import {fetchDataFromAPIEndpoint, updateDataToAPIEndpoint} from '../axios';
import {Link} from 'react-router-dom';

import './SAShopifyBaseProducts.scss';
import {Button, Checkbox, Spinner} from '@shopify/polaris';

export const SuperAdminShopifyBaseProducts = () => {
  const [products, setProducts] = useState();
  const [customizableProducts, setCustomizableProducts] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    await fetchDataFromAPIEndpoint('/baseProducts').then((d) => {
      console.log(d);
      const prod = [];

      const custProd = [];
      const checkedItems = [];
      d.data.shopifyProductsList.forEach((sp) => {
        console.log(sp.is_customizable);
        if (!!sp.is_customizable) {
          checkedItems.push(sp._id);
          custProd.push(sp);
        } else {
          prod.push(sp);
        }
      });
      setChecked([...checked, ...checkedItems]);
      setProducts(prod);

      setCustomizableProducts(custProd);

      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const saveProducts = async () => {
    setLoading(true);
    const res = await updateDataToAPIEndpoint('updateBaseProducts', {
      ids: checked
    });
    setLoading(false);
    await fetchData();
    console.log(res);
  };

  const makeAllCustomisableProducts = async () => {
    setLoading(true);
    const res = await updateDataToAPIEndpoint('updateBaseProducts', {
      ids: products.map(({_id}) => _id)
    });
    await fetchData();
    setLoading(false);
  };

  const [checked, setChecked] = useState([]);
  const handleChange = useCallback(
    (newChecked, id) => {
      console.log(newChecked);
      if (newChecked) {
        setChecked([...checked, id]);
      } else {
        setChecked(checked.filter((d) => d !== id));
      }
      console.log(checked);
    },
    [checked]
  );
  const checkIfActive = (id) => {
    return checked.find((d) => d === id);
  };
  const ProductItem = ({p}) => {
    return (
      <div className="SA__products-item" key={p._id}>
        <div className="SA__products-image">
          <Link to={'/admin/products/' + p._id}>
            <img
              height={100}
              src={p.shopifyProduct?.image?.src || './../logo.svg'}
              alt={p.shopifyProduct?.image?.src || './../logo.svg'}
            />
          </Link>
        </div>
        <div className="SA__products-checkbox">
          <Checkbox
            label="Customizable"
            checked={checkIfActive(p._id)}
            onChange={(e) => handleChange(e, p._id)}
          />
        </div>
        <div>{p.shopifyProduct.title}</div>
        <div>{p.shopifyProduct.variants[0].price}</div>
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <Spinner accessibilityLabel="Spinner example" size="large" />
      ) : (
        <>
          <h2 className="SA__main_heading">Base Products </h2>
          <Button onClick={saveProducts}>Save </Button>{' '}
          <Button onClick={makeAllCustomisableProducts}>
            makeAllCustomisableProducts{' '}
          </Button>
          <h2 className="SA__heading">Customizable Products</h2>
          <div className="SA__products">
            {customizableProducts?.map((p, key) => (
              <ProductItem p={p} key={key} />
            ))}
          </div>
          <h2 className="SA__heading">Products</h2>
          <div className="SA__products">
            {products?.map((p, key) => (
              <ProductItem p={p} key={key} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
