import {
  SET_BASE_PRODUCTS,
  SET_BASE_PRODUCTS_LOADING,
  SET_CLUB_OWNER_DATA
} from '../actions/types';

export const initialState = {
  baseProducts: [],
  clubOwnerData: ''
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BASE_PRODUCTS: {
      return {
        ...state,
        baseProducts: action.payload
      };
    }
    case SET_CLUB_OWNER_DATA: {
      return {
        ...state,
        clubOwnerData: action.payload
      };
    }
    case SET_BASE_PRODUCTS_LOADING: {
      return {
        ...state,
        baseProductsLoading: action.payload
      };
    }

    default: {
      return {...state};
    }
  }
};
