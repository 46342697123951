//import thunk from 'redux-thunk';

import {configureStore} from '@reduxjs/toolkit';
import {cartReducer} from './club-owner/reducers/cartReducer';
import {productsReducer} from './club-owner/reducers/productsReducer';
import {userReducer} from './user/reducers';
//import {applyMiddleware} from 'redux';

export default configureStore(
  {
    reducer: {
      cart: cartReducer,
      user: userReducer,
      products: productsReducer
    }
  }
  //  applyMiddleware(thunk)
);
