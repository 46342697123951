import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Button, ButtonType} from '../global/common/Button';
import {Loader} from '../global/common/Loader';
import {setCartData} from './actions';
import './Cart.scss';
import {getFiltersFields} from './Products';
import {FILTER_NAMES, getTag} from './ProductsUtils';
const getKey = (bP) =>
  getTag(bP.shopifyProduct, FILTER_NAMES.team, '-', 'rightPart')?.key;
export const getProductsDataFromCartData = (
  cartData,
  baseProducts,
  filterValue = false
) =>
  cartData?.map(
    (d) =>
      baseProducts?.filter((bP) => {
        if (filterValue) {
          if (
            bP._id === d &&
            getKey(bP)?.trim().toLowerCase() ===
              filterValue?.trim().toLowerCase()
          ) {
            return bP;
          }
        } else {
          if (bP._id === d) {
            return bP;
          }
        }
      })[0]
  );

export const Cart = () => {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.data);
  const baseProductsLoading = useSelector(
    (state) => state.products.baseProductsLoading
  );
  const [teams, setTeams] = useState([]);
  const [withoutTeamProduct, setWithoutTeamProduct] = useState([]);

  const baseProducts = useSelector((state) => state.products.baseProducts);

  useEffect(() => {
    updateCartProductData(cartData);
  }, [baseProducts]);

  const updateCartProductData = (data) => {
    //newdata.splice(newdata.indexOf(p._id), 1);

    const resFilterData = getFiltersFields(baseProducts, true);

    setTeams(resFilterData.teamsData);
    setWithoutTeamProduct([]);
    data?.map((d) =>
      baseProducts?.filter((bP) => {
        if (bP._id === d && !getKey(bP)) {
          const newData2 = withoutTeamProduct;
          newData2.push(bP);
          const unique = [...new Set(newData2.map((item) => item))];
          setWithoutTeamProduct(unique);
        }
      })
    );
  };

  const onRemoveProduct = (p) => {
    const newdata = [];
    cartData.forEach((d) => {
      if (d !== p._id) {
        newdata.push(d);
      }
    });
    dispatch(setCartData(newdata));
    updateCartProductData(newdata);
  };

  if (!!baseProductsLoading || !baseProducts.length) {
    return <Loader />;
  }
  console.log(cartData);

  return (
    <div id="cartID">
      {!cartData.length || !baseProducts.length ? (
        <div className="cart__empty">
          <div>Warenkorb leeren</div>

          <Button
            onClick={() => (window.location.href = '/')}
            className="products-button"
          >
            Produkte hinzufügen
          </Button>
        </div>
      ) : (
        <div className="cart">
          {teams?.map(
            (t, key) =>
              !!getProductsDataFromCartData(
                cartData,
                baseProducts,
                t.value
              ).filter(Boolean).length && (
                <div className="cart__section" key={key}>
                  <h2 className="cart__section_heading">{t.label} ARTIKEL</h2>
                  <div className="cart__item_wrapper">
                    {getProductsDataFromCartData(
                      cartData,
                      baseProducts,
                      t.value
                    )
                      .filter(Boolean)
                      ?.map((c, key) => (
                        <div className="cart__item" key={key}>
                          <div className="cart__image">
                            <img
                              id={c._id}
                              src={c.shopifyProduct.images[0].src}
                              alt={'product'}
                            />
                          </div>
                          <Button
                            onClick={() => onRemoveProduct(c)}
                            className="products-button"
                            underline
                            strechcontent
                          >
                            ENTFERNEN
                          </Button>
                          <div className="products-title">
                            {c.shopifyProduct.title}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )
          )}
          {!!withoutTeamProduct.length && (
            <div className="cart__section">
              <h2 className="cart__section_heading">ANDERE ARTIKEL</h2>
              <div className="cart__item_wrapper">
                {withoutTeamProduct.filter(Boolean)?.map((c, key) => (
                  <div className="cart__item" key={key}>
                    <div className="cart__image">
                      <img
                        id={c._id}
                        src={c.shopifyProduct?.images[0].src}
                        alt={'product'}
                      />
                    </div>
                    <Button
                      onClick={() => onRemoveProduct(c)}
                      className="products-button"
                      underline
                      strechcontent
                    >
                      ENTFERNEN
                    </Button>
                    <div className="products-title">
                      {c.shopifyProduct?.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
