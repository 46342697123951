import './LogoPreview.scss';
export const LogoPreview = ({url, style}) => {
  return (
    <div className="logo-preview__wrapper" style={style}>
      <img
        className="logo-preview__logo-image"
        src={url}
        alt={'Logo Preview'}
      />
    </div>
  );
};
