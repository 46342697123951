import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Navigate, useNavigate} from 'react-router-dom';
import {URLList} from '../App';
import {saveDataToAPIEndpoint, saveFileDataToAPIEndpoint} from '../axios';
import {Button} from '../global/common/Button';
import {Loader, SpinnerInternal} from '../global/common/Loader';
import {setCartData, setConfigData} from './actions';
import './ClubInfo.scss';
import {initialConfigData, resetLocalStorage} from './reducers/cartReducer';

const checkIfEmailExists = async (email) => {
  const res = await saveDataToAPIEndpoint('/checkUserEmail', {
    email
  });
  return res.data;
};

export const onSaveClub = () => async (dispatch, getState) => {
  console.log('onSaveClub');
  const cartData = getState().cart.data;
  const configData = getState().cart.config;

  dispatch(setConfigData({...configData, loading: true}));

  const isEmailExists = await checkIfEmailExists(configData.email);
  if (isEmailExists) {
    dispatch(
      setConfigData({
        ...configData,
        isEmailExists: isEmailExists,
        loading: false
      })
    );

    return false;
  } else {
    const clubProducts = cartData.map((c) => {
      return {product_id: c, is_curved_text: configData.isCurved};
    });
    try {
      var file = await fetch(configData.logo.url).then((r) => r.blob());
      const myFile = new File([file], configData.logo.filename);
      const data = {
        selectedColorsList: configData.selectedColorsList,
        colorsListBasedOnCart: configData.colorsListBasedOnCart,
        clubName: configData.clubName,
        firstLineText: configData.firstLineText,
        secondLineText: configData.secondLineText,
        isCurved: configData.isCurved,
        fullname: configData.fullname,
        email: configData.email,
        phone: configData.phone,
        clubProducts: clubProducts,
        clubLogo: myFile,
        additionalRequirenments: configData.additionalRequirenments
      };

      saveFileDataToAPIEndpoint('/addNewClubRequest', data).then((res) => {
        console.log(res);
        if (res.status === 200) {
          resetLocalStorage();
          window.location.href = URLList.Success;
          // dispatch(setConfigData(initialConfigData));
          // dispatch(setCartData([]));
        }
      });
    } catch (err) {
      console.log('err in saving:', err);
      alert('upload the logo again, and try again');
      dispatch(setConfigData({...configData, loading: false, logo: {url: ''}}));
      window.location.href = URLList.ClubConfiguration;
    }
  }
};

export const ClubInfo = () => {
  const dispatch = useDispatch();

  const configData = useSelector((state) => state.cart.config) || '';
  const cartData = useSelector((state) => state.cart.data) || [];

  const handleOnConfirm = () => {
    dispatch(onSaveClub());
  };
  useEffect(() => {
    dispatch(setConfigData({...configData, loading: false}));
  }, []);

  const validateEmail = String(configData.email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  const phoneno = /^\+?(?:[0-9][ -]?){6,14}[0-9]$/;
  const validPhone = String(configData?.phone).match(phoneno);
  const isFormValidate = !!validPhone && !!validateEmail;

  if (!!configData.loading) {
    return <SpinnerInternal />;
  }

  return cartData.length ? (
    <div className="club-info">
      <h2>
        FAST GESCHAFFT! <br />
        GEBE DEINE KONTAKTDATEN EIN DAMIT WIR DICH KONTAKTIEREN KÖNNEN.
      </h2>
      <div className="club-info__form">
        <div className="form-field">
          <label htmlFor="ClubName">TEAMNAME EINGEBEN*</label>
          <input
            onChange={(e) =>
              dispatch(setConfigData({...configData, clubName: e.target.value}))
            }
            type={'text'}
            id="ClubName"
            value={configData.clubName}
            placeholder="TEAMNAME EINTRAGEN"
          />
        </div>
        <div className="form-field">
          <label htmlFor="fullname">VORNAME, NAME*</label>
          <input
            onChange={(e) =>
              dispatch(setConfigData({...configData, fullname: e.target.value}))
            }
            type={'text'}
            id="fullname"
            value={configData.fullname}
            placeholder={'NAME, VORNAME'}
          />
        </div>
        <div className="form-field">
          {configData.isEmailExists && (
            <p className="form-error">
              Sie sind bereits mit dieser E-Mail-Adresse registriert.
            </p>
          )}
          {configData.email && !validateEmail && (
            <p className="form-error">Bitte gültige Email-Adresse eingeben.</p>
          )}

          <label htmlFor="email">E-MAIL-ADRESSE*</label>
          <input
            onChange={(e) =>
              dispatch(
                setConfigData({
                  ...configData,
                  email: e.target.value,
                  isEmailExists: false
                })
              )
            }
            type={'text'}
            id="email"
            value={configData.email}
            placeholder={'E-MAIL-ADRESSE'}
          />
        </div>
        <div className="form-field">
          {configData.phone && !validPhone && (
            <p className="form-error">Bitte gültige Telefonnummer eingeben.</p>
          )}
          <label htmlFor="phone">TELEFONNUMMER*</label>
          <input
            onChange={(e) =>
              dispatch(setConfigData({...configData, phone: e.target.value}))
            }
            type={'text'}
            id="phone"
            value={configData.phone}
            placeholder={'TELEFONNUMMER'}
          />
        </div>

        <Button
          onClick={handleOnConfirm}
          className="club-info__save-button"
          disabled={!configData.isValidData || !isFormValidate}
          strechcontent
        >
          BESTÄTIGEN
        </Button>
      </div>
    </div>
  ) : (
    <Link to={'/products'} className="products-button">
      Add Products
    </Link>
  );
};
