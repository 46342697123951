import './SelectColor.scss';

export const SelectColor = ({
  colorsList,
  selectedColor,
  onColorSelect,
  style = {}
}) => {
  return (
    <div className="SelectColor" style={style}>
      {colorsList.map((cl, key) => (
        <div key={key} className="SelectColor__Main">
          <div
            className={`SelectColor__ColorWrapper ${
              selectedColor?.id === cl?.id
                ? 'SelectColor__ColorWrapper_Selected'
                : ''
            }`}
          >
            <div
              className={'SelectColor__Color SelectColor__' + cl.color}
              onClick={() => onColorSelect(cl)}
            ></div>
          </div>
          <span className="SelectColor__ColorLabel"> {cl.label}</span>
        </div>
      ))}
    </div>
  );
};
