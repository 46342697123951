import {Button, Icon} from '@shopify/polaris';
import {CartMajor} from '@shopify/polaris-icons';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate} from 'react-router-dom';
import './Navigation.scss';
import logo from './logo.svg';
import {LinkWrapper} from './common/LinkWrapper';
import {URLList} from '../App';
import {setCSFRTokenData, setUserProfileData} from '../user/actions';
import {Sidebar} from './common/Sidebar';
import {useState} from 'react';
import {ButtonType} from './common/Button';
export const Navigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartData = useSelector((state) => state.cart.data) || [];
  const profileData = useSelector((state) => state.user.profileData);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const onLogout = () => {
    localStorage.setItem('csrfToken', '');
    dispatch(setCSFRTokenData(''));
    dispatch(setUserProfileData(null));
    navigate('/');
    // await saveDataToAPIEndpoint('/logout', {
    //   csrfToken
    // }).then((res) => {
    //   console.log(res);

    // });
  };

  return (
    <nav className="Nav">
      <div className="Fixed_Width">
        <a
          href={URLList.PAPILLONSPORTSWEARWeb}
          target="_blank"
          rel="noreferrer"
          className="desktop"
        >
          ZURÜCK ZU PAPILLON-SPORTSWEAR.COM
        </a>
        <LinkWrapper
          onClick={() => setIsSidebarOpen(true)}
          className="mobile help"
        >
          HILFE
        </LinkWrapper>
      </div>

      <div className="Fixed_Width">
        <LinkWrapper to={URLList.Home}>
          <img className="Nav-logo" src={logo} alt="PAPILLON-SPORTSWEAR" />
        </LinkWrapper>
      </div>
      <div className="Nav__Right_Items Fixed_Width">
        {!!profileData?.email ? (
          <>
            {/* <LinkWrapper to={'/user'}>{profileData.lastname}</LinkWrapper> */}
            {!!profileData?.club_id && (
              <LinkWrapper to={'/dashboard'}>Dashboard</LinkWrapper>
            )}
            <LinkWrapper onClick={onLogout}>{'Logout'}</LinkWrapper>
          </>
        ) : (
          <LinkWrapper to={URLList.Login}>Login</LinkWrapper>
        )}

        {!!profileData?.isAdmin && (
          <LinkWrapper to={'/admin'}>Admin</LinkWrapper>
        )}
      </div>
      {/* 
      <NavLink to={'/'}>Home</NavLink>

      <NavLink to={'/products/'}>Products</NavLink>

      <NavLink to={'/cart'}>
        <div className={'Nav__cart'}>
          <Icon source={CartMajor}>ss</Icon>
          <span>{cartData.length}</span>
        </div>
      </NavLink> */}
      {isSidebarOpen && (
        <Sidebar
          onClose={() => setIsSidebarOpen(false)}
          content={
            <div>
              <h2 style={{paddingBottom: '38px'}}>
                HILFE BEI DER ARTIKELAUSWAHL
              </h2>
              <p>
                In dieser Ansicht wählst Du die Artikel für deine Kollektion
                aus.
              </p>
            </div>
          }
        ></Sidebar>
      )}
    </nav>
  );
};
