import {CancelMajor, CancelMinor} from '@shopify/polaris-icons';
import './Sidebar.scss';

export const Sidebar = ({content, onClose}) => {
  return (
    <div className="Sidebar">
      <div className="Overlay"></div>

      <div className="ContentWrapper">
        <CancelMinor className="Close" onClick={onClose} />
        <div className="Content">{content}</div>
      </div>
    </div>
  );
};
