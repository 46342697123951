import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {fetchDataFromAPIEndpoint, saveDataToAPIEndpoint} from '../axios';
import {Button, ButtonType} from '../global/common/Button';
import {Loader, Spinner, SpinnerInternal} from '../global/common/Loader';
import {LinkWrapper} from '../global/common/LinkWrapper';
import {setBaseProductsData, setCartData} from './actions';
import './Products.scss';
import {
  defaultColors,
  defaultProductType,
  defaultTeam,
  FILTER_NAMES,
  getActiveFilterValue,
  getColorFromGermanWord,
  getColorId,
  getFilter,
  getImage,
  getOnlyProductsWithColors,
  getTag,
  onImageMouseLeave,
  onImageMouseOver
} from './ProductsUtils';

import allColorFilterImage from '../global/all_colors_filter_bg.svg';
import successCheckmark from '../global/successCheckmark.svg';

export const getFiltersFields = (newData, allLast = false) => {
  const colorData = [...defaultColors];
  const teamsDataTmp = [...defaultTeam];
  const productTypeData = [...defaultProductType];

  newData.forEach((bProduct) => {
    const colorTag = getTag(bProduct.shopifyProduct, FILTER_NAMES.colors);
    if (!!colorTag && !colorData.find((cl) => cl.label === colorTag.value)) {
      colorData.push({
        label: colorTag.value,
        value: getColorFromGermanWord(colorTag.value),
        id: colorTag.key + colorTag.value,
        active: false
      });
    }

    const teamTag = getTag(
      bProduct.shopifyProduct,
      FILTER_NAMES.team,
      '-',
      'rightPart'
    );
    if (!!teamTag && !teamsDataTmp.find((td) => td.label === teamTag.key)) {
      teamsDataTmp.push({
        label: teamTag.key,
        value: teamTag.key,
        id: teamTag.key + teamTag.value,
        active: false
      });
    }

    const productType = bProduct.shopifyProduct.product_type
      .toLowerCase()
      .trim();

    if (
      productType &&
      !productTypeData.find((cl) => cl.value === productType)
    ) {
      productTypeData.push({
        label: productType,
        value: productType,
        id: 'product_type' + productType,
        active: false
      });
    }
  });

  //Herren, Damen, Jungs, Mädchen
  const herren = teamsDataTmp.filter(
    (t) => t.label.toLocaleLowerCase().indexOf('herr') !== -1
  );
  const damen = teamsDataTmp.filter(
    (t) => t.label.toLocaleLowerCase().indexOf('dam') !== -1
  );
  const jungs = teamsDataTmp.filter(
    (t) => t.label.toLocaleLowerCase().indexOf('jun') !== -1
  );
  const mädchen = teamsDataTmp.filter(
    (t) => t.label.toLocaleLowerCase().indexOf('mäd') !== -1
  );
  const all = teamsDataTmp.filter(
    (t) => t.label.toLocaleLowerCase().indexOf('all') !== -1
  );
  const teamsData = [];
  if (!allLast) all.length && teamsData.push(all[0]);
  herren.length && teamsData.push(herren[0]);
  damen.length && teamsData.push(damen[0]);
  jungs.length && teamsData.push(jungs[0]);
  mädchen.length && teamsData.push(mädchen[0]);
  if (allLast) all.length && teamsData.push(all[0]);

  console.log('colorData: ', productTypeData);
  return {colorData, teamsData, productTypeData};
};

export const Products = () => {
  const navigate = useNavigate();
  const [filteredProductsData, setFilteredProductsData] = useState([]);

  const [teams, setTeams] = useState([defaultTeam]);
  const [colors, setColors] = useState(defaultColors);
  const [productType, setProductType] = useState([defaultProductType]);

  const [teamsFilterMobileOpen, setTeamsFilterMobileOpen] = useState(false);
  const [colorsFilterMobileOpen, setColorsFilterMobileOpen] = useState(false);
  const [productTypeFilterMobileOpen, setProductTypeFilterMobileOpen] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const dispatch = useDispatch();

  const cartData = useSelector((state) => state.cart.data);
  const baseProducts = useSelector((state) => state.products.baseProducts);
  const clubOwnerData = useSelector((state) => state.products.clubOwnerData);
  const baseProductsLoading = useSelector(
    (state) => state.products.baseProductsLoading
  );

  const fetchBaseProducts = async () => {
    setLoading(true);
    const res = await fetchDataFromAPIEndpoint('/baseProducts');
    setLoading(false);
    return res.data;
  };

  // const fetchProductMetaFields = async (data) => {
  //   console.log(data);
  //   return await saveDataToAPIEndpoint('/getProductMetaFields', {
  //     productIds: data
  //   });
  // };

  useEffect(() => {
    if (!!baseProducts.length) {
      const resFilterData = getFiltersFields(
        getOnlyProductsWithColors(baseProducts)
      );
      setColors(resFilterData.colorData);
      setTeams(resFilterData.teamsData);
      setProductType(resFilterData.productTypeData);
      setLoadingFilters(false);
      setFilteredProductsData(getOnlyProductsWithColors(baseProducts));
    }
    // if (!baseProducts.length) {
    //   fetchBaseProducts().then((data) => {
    //     if (!data.shopifyProductsList.length) {
    //       return alert('problem in fetching data');
    //     }

    //     const finalData = getOnlyProductsWithColors(data.shopifyProductsList);

    //     dispatch(setBaseProductsData(finalData));
    //     setFilteredProductsData(finalData);
    //     setFiltersFields(finalData);
    //   });
    // }
  }, [baseProductsLoading]);

  const onAddProduct = (p) => {
    dispatch(setCartData([...cartData, p._id]));
  };
  const onRemoveProduct = (p) => {
    const newdata = [];
    cartData.forEach((d) => {
      if (d !== p._id) {
        newdata.push(d);
      }
    });
    //newdata.splice(newdata.indexOf(p._id), 1);

    dispatch(setCartData(newdata));
  };

  const isTeamsFilterActive = teams.find(
    (t) => t.active === true && t.value !== defaultTeam[0].value
  );
  const isColorssFilterActive = colors.find(
    (t) => t.active === true && t.value !== defaultColors[0].value
  );
  const isPTypeFilterActive = productType.find(
    (t) => t.active === true && t.value !== defaultProductType[0].value
  );
  const isFilterActive =
    !!isTeamsFilterActive || !!isColorssFilterActive || !!isPTypeFilterActive;

  const clearFilter = () => {
    setFilteredProductsData(baseProducts);
    setTeams((prev) =>
      prev.map((el, i) => {
        if (el.value === defaultTeam[0].value) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
    setColors((prev) =>
      prev.map((el, i) => {
        if (el.value === defaultColors[0].value) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
    setProductType((prev) =>
      prev.map((el, i) => {
        if (el.value === defaultProductType[0].value) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
  };

  const onFilterChange = (filterType, key) => {
    let filteredData = [];

    let currentTeam = getActiveFilterValue(teams);
    let currentColors = getActiveFilterValue(colors);
    let currentProductType = getActiveFilterValue(productType);

    if (filterType === FILTER_NAMES.team) {
      currentTeam = teams[key];
    } else if (filterType === FILTER_NAMES.colors) {
      currentColors = colors[key];
    } else if (filterType === FILTER_NAMES.productType) {
      currentProductType = productType[key];
    }

    // Team Filter
    filteredData = !currentTeam?.id
      ? baseProducts
      : baseProducts.filter((fpd) => {
          const teamFil = getTag(
            fpd.shopifyProduct,
            FILTER_NAMES.team,
            '-',
            'rightPart'
          )?.key;

          if (!!teamFil && currentTeam.id && teamFil === currentTeam.label) {
            return fpd;
          }
        });

    //Color Filter
    filteredData = !currentColors?.id
      ? filteredData
      : filteredData.filter((fpd) => {
          const colorFil = getTag(
            fpd.shopifyProduct,
            FILTER_NAMES.colors
          )?.value;
          console.log(colorFil);
          if (
            !!colorFil &&
            currentColors.id &&
            colorFil === currentColors.label
          ) {
            return fpd;
          }
        });

    // Product Type filter
    filteredData = !currentProductType?.id
      ? filteredData
      : filteredData.filter((fpd) => {
          const pTypeFil = fpd.shopifyProduct.product_type.toLowerCase().trim();
          if (
            !!pTypeFil &&
            currentProductType.id &&
            pTypeFil === currentProductType.value
          ) {
            return fpd;
          }
        });

    setFilteredProductsData(filteredData);

    console.log(currentTeam, currentColors, currentProductType);
  };

  const onFilterTeamsChange = (key) => {
    console.log(teams[key]);

    setTeams((prev) =>
      prev.map((el, i) => {
        if (i === key) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
    onFilterChange(FILTER_NAMES.team, key);
  };
  const onFilterColorsChange = (key) => {
    console.log(colors[key]);

    setColors((prev) =>
      prev.map((el, i) => {
        if (i === key) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
    onFilterChange(FILTER_NAMES.colors, key);
  };
  const onFilterProductTypeChange = (key) => {
    console.log(key);

    setProductType((prev) =>
      prev.map((el, i) => {
        if (i === key) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
    onFilterChange(FILTER_NAMES.productType, key);
  };

  const isProductInCart = (p) => cartData.find((cd) => cd === p._id);
  const isProductInClub = (p) =>
    clubOwnerData?.clubProducts?.find(
      (cp) => cp.localProductData.product_id._id === p._id
    );

  if (!!baseProductsLoading) {
    return <Loader />;
  }

  return (
    <div>
      {loadingFilters ? (
        <SpinnerInternal />
      ) : (
        <div className="Filters">
          <div
            className={
              teamsFilterMobileOpen
                ? ' Filters_Open Filters__types'
                : 'Filters__types'
            }
          >
            <span
              className="Filters__name"
              onClick={() => setTeamsFilterMobileOpen(!teamsFilterMobileOpen)}
            >
              Kategorie<span className="desktop">:</span>
            </span>
            {teams.map((t, key) => (
              <Button
                key={key}
                onClick={() => onFilterTeamsChange(key)}
                type={t.active ? ButtonType.Primary : ButtonType.Secondary}
                hoverDisabled={t.active}
                width='110px'
              >
                {t.label}
              </Button>
            ))}
          </div>

          <div
            className={
              colorsFilterMobileOpen
                ? ' Filters_Open Filters__colors'
                : 'Filters__colors'
            }
          >
            <span
              className="Filters__name"
              onClick={() => setColorsFilterMobileOpen(!colorsFilterMobileOpen)}
            >
              FARBE<span className="desktop">:</span>
            </span>
            {colors.map((c, key) =>
              c.value === 'all' ? (
                <Button
                  key={key}
                  onClick={() => onFilterColorsChange(key)}
                  type={c.active ? ButtonType.Primary : ButtonType.Secondary}
                  hoverDisabled
                  width="110px"
                >
                  {c.label}
                </Button>
              ) : (
                <Button
                  key={key}
                  onClick={() => onFilterColorsChange(key)}
                  type={c.active ? ButtonType.Primary : ButtonType.Secondary}
                  hoverDisabled
                  width="110px"
                  borderColor={c.value}
                  fontColor={c.value}
                  backgroundColor={c.active && c.value}
                >
                  {c.label}
                </Button>
              )
            )}
          </div>

          <div
            className={
              productTypeFilterMobileOpen
                ? ' Filters_Open Filters__product_tag'
                : 'Filters__product_tag'
            }
          >
            <span
              className="Filters__name"
              onClick={() =>
                setProductTypeFilterMobileOpen(!productTypeFilterMobileOpen)
              }
            >
              PRODUKT<span className="desktop">:</span>
            </span>
            {productType.map((pt, key) => (
              <Button
                key={key}
                onClick={() => onFilterProductTypeChange(key)}
                type={pt.active ? ButtonType.Primary : ButtonType.Secondary}
                hoverDisabled={pt.active}
                width="110px"
              >
                {pt.label}
              </Button>
            ))}
          </div>
        </div>
      )}

      {isFilterActive && (
        <div className="Filters__clear">
          <LinkWrapper underline onClick={clearFilter}>
            Alle Filter zurücksetzen
          </LinkWrapper>
        </div>
      )}
      <div id="products">
        {filteredProductsData?.map((p, key) => (
          <div
            className={
              'products-item ' + (isProductInCart(p) ? 'product-in-cart' : '')
            }
            key={key}
          >
            {isProductInCart(p) && (
              <div className="products-item-added-label">
                <img src={successCheckmark} alt="added" />
                <span>HINZUGEFÜGT</span>
              </div>
            )}
            <div
              onClick={() => navigate('product-detail/' + p._id)}
              onMouseOver={() => onImageMouseOver(p._id)}
              onMouseLeave={() => onImageMouseLeave(p._id)}
              className={
                'products-image ' +
                (isProductInCart(p) ? 'products-item-added' : '')
              }
            >
              <img id={p._id} src={getImage(p)} alt={'product'} />
              <img
                className="products-image-hover"
                id={p._id + 'hover'}
                src={getImage(p, true)}
                alt={'product'}
              />
            </div>
            {isProductInClub(p) ? (
              <Button disabled className="products-button" strechcontent>
                Bereits im Club
              </Button>
            ) : isProductInCart(p) ? (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveProduct(p);
                }}
                className="products-button"
                strechcontent
                minHeight="55px"
              >
                ENTFERNEN
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onAddProduct(p);
                }}
                className="products-button"
                strechcontent
                minHeight="55px"
              >
                HINZUFÜGEN
              </Button>
            )}
            <div className="products-title">{p.shopifyProduct.title}</div>
            <div className="products-price">
              {p.shopifyProduct.variants.length
                ? p.shopifyProduct.variants[0].price + '€'
                : 'keine Angabe'}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
