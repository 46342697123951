import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {saveDataToAPIEndpoint} from '../axios';
import {Button} from '../global/common/Button';
import {setCSFRTokenData} from '../user/actions';
import './Login.scss';

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const csrfToken = useSelector((state) => state.user.csrfToken) || '';

  useEffect(() => {
    if (csrfToken) {
      navigate('/');
    }
  }, [csrfToken, navigate]);

  const onLogin = async () => {
    console.log(password);
    await saveDataToAPIEndpoint('/login', {
      username,
      password
    })
      .then((res, err) => {
        console.log(res);
        console.log(err);
        console.log('login err::', err);
        dispatch(setCSFRTokenData(res.data));
      })
      .catch((err) => {
        alert(err?.response?.data || 'No Access');
      });

    // set token in cookie
    // document.cookie = `token=${response.data}`;
  };

  const onRegister = async () => {
    const response = await saveDataToAPIEndpoint('/register', {
      username: username,
      email: username,
      password: password,
      firstname: 'Pankaj',
      lastname: 'Narola'
    });
    console.log(response);
  };

  return (
    <div className="Login">
      <h1>LOGIN</h1>
      <div className="Login__Form">
        <div className="form-field">
          <label htmlFor="email">E-MAIL-ADRESSE:</label>
          <input
            onInput={(e) => setUsername(e.target.value)}
            type={'text'}
            id="email"
            placeholder="E-MAIL-ADRESSE"
          />
        </div>
        <div className="form-field">
          <label htmlFor="password">PASSWORT:</label>
          <input
            onInput={(e) => setPassword(e.target.value)}
            type={'password'}
            id="password"
            placeholder="PASSWORT"
          />
          {/* <LinkWrapper
            to={'/reset-password'}
            className="Login__Form__ForgetPassword"
          >
            PASSWORT VERGESSEN?
          </LinkWrapper> */}
        </div>
        <Button strechcontent onClick={onLogin}>
          LOGIN
        </Button>
        <div>
          <Button strechcontent onClick={onRegister}>
            Register
          </Button>
        </div>
      </div>
    </div>
  );
};
