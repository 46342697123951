import {Button} from '@shopify/polaris';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchDataFromAPIEndpoint, saveDataToAPIEndpoint} from '../axios';
import {setCSFRTokenData, setUserProfileData} from '../user/actions';
import {setCartData} from './actions';
import './Success.scss';
import SuccessGif from './SuccessGif.gif'; // relative path to image

export const Success = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  return (
    <div className="Success">
      <img src={SuccessGif} alt={'Success'} />
      <p>
        GRATULATION. DEINE KOLLEKTION IST FERTIG ANGELEGT.
        <br />
        WIR HABEN DIR HIERZU EINE BESTÄTIGUNGSMAIL ZUGESCHICKT.
        <br />
        <br />
        WIR PRÜFEN NUN DEINE ANFRAGE.
        <br />
        DIES GESCHIEHT IM NORMALFALL INNERHALB VON 24-48 STUNDEN.
        <br />
        <br />
        SOBALD WIR DEINE ANFRAGE GEPRÜFT HABEN, GEBEN WIR DEINE
        <br />
        KOLLEKTION FREI.
        <br />
        HIERZU ERHÄHLST DU EINE BESTÄTIGUNGSMAIL MIT ALLEN WEITEREN
        <br />
        INFORMATIONEN.
        <br />
        <br />
        VIELEN DANK FÜR DEIN VERTRAUEN!
      </p>
    </div>
  );
};
