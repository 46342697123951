import React, {useCallback, useEffect, useState} from 'react';
import {fetchDataFromAPIEndpoint, saveFileDataToAPIEndpoint} from '../axios';
import '@shopify/polaris/build/esm/styles.css';
import {Page, Card, Button, Text} from '@shopify/polaris';
import {LogoPreview} from '../global/LogoPreview';
import './SAClubOwnerRequestsDetails.scss';
import {useParams} from 'react-router-dom';

import html2canvas from 'html2canvas';
import {ClubStatusOptions, REACT_APP_BE_URL, textColorsList} from '../config';
import {Loader, SpinnerInternal} from '../global/common/Loader';
import {ImageConfigPreview} from '../global/common/ImageConfigPreview';
import {
  FILTER_NAMES,
  getColorFromGermanWord,
  getFilter,
  getTag
} from '../club-owner/ProductsUtils';
import {saveAs} from 'file-saver';
const ProductStatus = {
  approvedProducts: 'approvedProducts',
  notApprovedProducts: 'notApprovedProducts'
};

export const downloadBase64File = (contentType, base64Data, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const ProductListView = React.memo(
  ({
    products,
    status,
    currentLogo,
    clubOwnerRequests,
    currentFirstLineText,
    currentSecondLineText
  }) => {
    const getCurrentColor = (cOwnerRequests, shopifyProductData) => {
      const colorFil = getTag(shopifyProductData, FILTER_NAMES.colors);
      const colorCode = getColorFromGermanWord(
        colorFil.value.trim().toLowerCase()
      );
      console.log(colorCode);
      const textColorId = cOwnerRequests.selectedColorsList.find(
        (sCL) => sCL.cartColorID === colorCode
      )?.selectedTextColorID;

      return textColorsList.find((tcl) => tcl.id === textColorId);
    };

    return (
      <div className="club-owner-requests__products">
        {products.map((cp, key) => (
          <div key={key + status}>
            <b>
              {cp.localProductData.product_id?.is_customizable
                ? 'Customised Product Preview'
                : 'Non Customised Product Preview'}
            </b>

            <div>Id: {cp.shopifyProductData.id}</div>
            <div>Name: {cp.shopifyProductData.title}</div>

            <div>
              {cp.localProductData.product_id?.is_customizable ? (
                <ImageConfigPreview
                  frontImage={cp.shopifyProductData?.images[0].src}
                  backImage={cp.shopifyProductData?.images[2].src}
                  thirdImage={cp.shopifyProductData?.images[1].src}
                  forthImage={cp.shopifyProductData?.images[3].src}
                  // position={cp[0].position}
                  logoImage={currentLogo}
                  isClubAdmin={ProductStatus.notApprovedProducts === status}
                  position={cp.localProductData.product_id?.position}
                  configData={{
                    isClubNameShowInBack:
                      clubOwnerRequests.isClubNameShowInBack,
                    firstLineText: currentFirstLineText,
                    isCurved: clubOwnerRequests.isCurved,
                    secondLineText: currentSecondLineText,
                    selectedColor: getCurrentColor(
                      clubOwnerRequests,
                      cp.shopifyProductData
                    )
                  }}
                  id={'Club-Owner-Requests' + key + status}
                />
              ) : (
                <div className="club-owner-requests__non-cust-products">
                  <div>
                    <img
                      alt="cp.shopifyProductData?.images[0].src"
                      src={cp.shopifyProductData?.images[0].src}
                      height="auto"
                      width="200"
                    />
                    <img
                      height="auto"
                      width="200"
                      alt="cp.shopifyProductData?.images[1].src"
                      src={cp.shopifyProductData?.images[1].src}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
);

export const SAClubOwnerRequestsDetails = React.memo(() => {
  const [clubOwnerRequests, setClubOwnerRequests] = useState();
  const [approvedProducts, setApprovedProducts] = useState();
  const [notApprovedProducts, setNotApprovedProducts] = useState();
  const [currentLogo, setCurrentLogo] = useState();
  const [currentFirstLineText, setCurrentFirstLineText] = useState();
  const [currentSecondLineText, setCurrentSecondLineText] = useState();

  const [loading, setLoading] = useState(false);
  const [dataSentLoading, setDataSentLoading] = useState(false);
  const [error, setError] = useState([]);
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    fetchDataFromAPIEndpoint('/clubOwnerRequests/' + params.id).then(
      (cbRequest) => {
        setClubOwnerRequests(cbRequest.data);
        setCurrentLogo(REACT_APP_BE_URL + cbRequest.data.clubLogo);
        setCurrentFirstLineText(cbRequest.data.firstLineText);
        setCurrentSecondLineText(cbRequest.data.secondLineText);

        setApprovedProducts(
          cbRequest.data.clubProducts.filter(
            (cp) => cp.localProductData.clubShopifyProductID !== 0
          )
        );
        setNotApprovedProducts(
          cbRequest.data.clubProducts.filter(
            (cp) => cp.localProductData.clubShopifyProductID === 0
          )
        );
        setLoading(false);
      }
    );
  }, [params.id]);

  const canvasToBlob = async (canvas) => {
    if (canvas.toBlob) {
      return new Promise(function (resolve) {
        canvas.toBlob(resolve);
      });
    } else {
      throw new Error('canvas.toBlob Invalid');
    }
  };

  const getScreenShot = (ele) => {
    let c = document.getElementById(ele); // or document.getElementById('canvas');

    return new Promise(async (resolve, reject) => {
      html2canvas(c, {
        logging: true,
        letterRendering: 1,
        //      allowTaint: true,,
        useCORS: true
        // onrendered: function (canvas) {
        //   var t = canvas.toDataURL().replace('data:image/png;base64,', '');
        //   downloadBase64File('image/png', t, 'image');
        // }
      })
        .then(async (canvas) => {
          //  var t = canvas.toDataURL().replace('data:image/png;base64,', '');
          // console.log(await canvasToBlob(canvas));
          // downloadBase64File('image/png', t, 'image');

          resolve(await canvasToBlob(canvas));
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const onApproveClubRequest = async (isReRequested) => {
    setDataSentLoading(true);
    let responsePromises = [];
    const formData = new FormData();
    const hasClubTextOnBack =
      !!clubOwnerRequests.firstLineText || !!clubOwnerRequests.secondLineText;

    formData.append('isReRequested', isReRequested);

    notApprovedProducts.forEach(async (cp, key) => {
      // Only generate images for custmisable products
      if (!!cp.localProductData.product_id?.is_customizable) {
        const myPromise = new Promise(async (resolve, reject) => {
          let secondImage = '';
          const keyPrefix =
            'Club-Owner-Requests' + key + ProductStatus.notApprovedProducts;

          const firstImage = await getScreenShot(keyPrefix + 'firstImageFront');
          formData.append('firstImage-' + cp.localProductData._id, firstImage);

          const thirdImage = await getScreenShot(keyPrefix + 'thirdImageFront');
          formData.append('thirdImage-' + cp.localProductData._id, thirdImage);

          const fourthImage = await getScreenShot(
            keyPrefix + 'fourthImageFront'
          );
          formData.append(
            'fourthImage-' + cp.localProductData._id,
            fourthImage
          );

          if (hasClubTextOnBack) {
            secondImage = await getScreenShot(keyPrefix + 'secondImageBack');
            formData.append(
              'secondImage-' + cp.localProductData._id,
              secondImage
            );
          }

          return resolve({
            id: cp.localProductData._id,
            firstImage: firstImage,
            secondImage: secondImage,
            thirdImage: thirdImage,
            fourthImage: fourthImage
          });
        });
        responsePromises.push(myPromise);
      }
    });

    Promise.all(responsePromises).then((responses) => {
      saveFileDataToAPIEndpoint('/createClubInShopify/' + params.id, formData)
        .then((cbRequest) => {
          setDataSentLoading(false);
          window.location.reload();
        })
        .catch((err) => {
          setError(err.response.data);
          console.log(err.response.data);
          setDataSentLoading(false);
        });
    });
  };

  const onLogoChange = (e) => {
    console.log(e.target.files[0]);
    setCurrentLogo(URL.createObjectURL(e.target.files[0]));
  };

  if (!!loading) {
    return <Loader />;
  }

  const getClubActionButton = () => {
    switch (clubOwnerRequests.clubStatus) {
      case ClubStatusOptions.ReRequested:
        return (
          <Button onClick={() => onApproveClubRequest(true)}>
            Re-Approve Club Request
          </Button>
        );
      case ClubStatusOptions.Requested:
        return (
          <Button onClick={() => onApproveClubRequest(false)}>
            Approve Club Request
          </Button>
        );
      case ClubStatusOptions.Rejected:
        return <Button>Club is Rejected </Button>;
      case ClubStatusOptions.Approved:
        return <Button>Club is Approved </Button>;

      default:
        return <Button>Club status unknown </Button>;
    }
  };

  return (
    <Page title="Club Owner Requests">
      {!!error.length && (
        <div className="Error">{error.map((err) => err.title)}</div>
      )}
      {clubOwnerRequests ? (
        <div className="club-owner-requests__wrapper">
          <Card sectioned>
            <div className="club-owner-requests__item">
              <div className="logo-section">
                <LogoPreview url={currentLogo} style={{paddingBottom: '8px'}} />
                <Button
                  onClick={() =>
                    saveAs(currentLogo, clubOwnerRequests.clubName + '_Logo')
                  }
                >
                  Download
                </Button>
                <br />
                <label htmlFor="fileUpload"> Upload New Logo: </label>
                <input
                  id="fileUpload"
                  type={'file'}
                  name="Upload new logo"
                  onChange={onLogoChange}
                />
                <br />
              </div>
              <table className="club-owner-requests__item-details">
                <tr>
                  <td>
                    <Text variant="headingMd" as="h2" alignment="start">
                      Team Name:
                    </Text>
                  </td>
                  <td>
                    <Text variant="bodyLg">{clubOwnerRequests.clubName}</Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text variant="headingMd" as="h2" alignment="start">
                      phone:
                    </Text>
                  </td>
                  <td>
                    <Text variant="bodyLg">{clubOwnerRequests.phone}</Text>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Text variant="headingMd" as="h2" alignment="start">
                      club status:
                    </Text>
                  </td>{' '}
                  <td>
                    <Text variant="bodyLg">{clubOwnerRequests.clubStatus}</Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text variant="headingMd" as="h2" alignment="start">
                      Name:
                    </Text>
                  </td>{' '}
                  <td>
                    <Text variant="bodyLg">{clubOwnerRequests.fullname}</Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text variant="headingMd" as="h2" alignment="start">
                      Email:
                    </Text>
                  </td>{' '}
                  <td>
                    <Text variant="bodyLg">{clubOwnerRequests.email}</Text>
                  </td>
                </tr>
              </table>

              <div style={{display: 'flex', flexFlow: 'column'}}>
                <label htmlFor="currentFirstLineText">Firstline Text: </label>
                <input
                  id="currentFirstLineText"
                  onChange={(e) => setCurrentFirstLineText(e.target.value)}
                  value={currentFirstLineText}
                />
                <br />
                <label htmlFor="currentSecondLineText">Secondline Text: </label>
                <input
                  id="currentSecondLineText"
                  onChange={(e) => setCurrentSecondLineText(e.target.value)}
                  value={currentSecondLineText}
                />
              </div>
            </div>
            {clubOwnerRequests?.additionalRequirenments && (
              <p style={{paddingBottom: '12px'}}>
                Zusätzliche Anforderungen:{' '}
                {clubOwnerRequests?.additionalRequirenments}
              </p>
            )}
            {dataSentLoading ? (
              <div className="club-owner-requests__loading_hint">
                <SpinnerInternal />
                <p>
                  <b>Do not close browser or this tab</b> <br />
                  <b> Hint:</b> This may take a while because it Generates
                  images and upload to server, Creates a Collection in Shopify,
                  Adds Products with new generated images, creates club Admin
                  Account, and finally sends email to the club Admin.
                </p>
              </div>
            ) : (
              getClubActionButton()
            )}
          </Card>
          {!!notApprovedProducts.length && (
            <>
              <Text variant="headingXl">Not Appoved Products </Text>
              <ProductListView
                products={notApprovedProducts}
                status={ProductStatus.notApprovedProducts}
                currentLogo={currentLogo}
                clubOwnerRequests={clubOwnerRequests}
                currentFirstLineText={currentFirstLineText}
                currentSecondLineText={currentSecondLineText}
              />{' '}
            </>
          )}
          {!!approvedProducts.length && (
            <>
              <Text variant="headingXl">Appoved Products </Text>
              <ProductListView
                products={approvedProducts}
                status={ProductStatus.approvedProducts}
                currentLogo={currentLogo}
                clubOwnerRequests={clubOwnerRequests}
                currentFirstLineText={currentFirstLineText}
                currentSecondLineText={currentSecondLineText}
              />
            </>
          )}
        </div>
      ) : (
        'no data'
      )}
    </Page>
  );
});
