import {useEffect, useState} from 'react';
import {fetchDataFromAPIEndpoint} from '../axios';

import './SAShopifyBaseProducts.scss';
import {useParams} from 'react-router-dom';
import {Button, ButtonType} from './../global/common/Button';

import {ImageLogoAndTextPosition} from '../global/ImageLogoAndTextPosition';
import {SpinnerInternal} from '../global/common/Loader';

export const SuperAdminShopifyBaseProductsDetailPage = () => {
  const [products, setProducts] = useState();
  const [p, setCurrentProduct] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const data = fetchDataFromAPIEndpoint('/baseProducts');
    if (data) {
      data.then((d) => {
        console.log(d.data.shopifyProductsList);
        setProducts(d.data.shopifyProductsList);
        setLoading(false);
      });
    }
  }, []);
  const params = useParams();
  useEffect(() => {
    const d = products?.find((s) => s._id === params.id);
    setCurrentProduct(d);
  }, [products, params]);

  if (loading) {
    return <SpinnerInternal />;
  }

  return (
    <div className="">
      <Button
        strechcontent={false}
        type={ButtonType.Secondary}
        to={'/admin/products'}
      >
        Back
      </Button>
      {p ? (
        <ImageLogoAndTextPosition
          frontImage={p?.shopifyProduct?.images[0].src}
          thirdImage={p?.shopifyProduct?.images[1].src}
          backImage={p?.shopifyProduct?.images[2].src}
          forthImage={p?.shopifyProduct?.images[3].src}
          position={p?.position}
        />
      ) : (
        <div>Wrong Product ID</div>
      )}

      <br />
      <br />
    </div>
  );
};
