import {Icon, Text} from '@shopify/polaris';
import {CancelMajor, SearchMajor} from '@shopify/polaris-icons';
import {useEffect, useState} from 'react';
import {fetchDataFromAPIEndpoint, saveDataToAPIEndpoint} from '../axios';
import {getFiltersFields} from '../club-owner/Products';
import {
  defaultColors,
  defaultProductType,
  defaultTeam,
  FILTER_NAMES,
  getActiveFilterValue,
  getTag
} from '../club-owner/ProductsUtils';
import {Button, ButtonType} from '../global/common/Button';
import {LinkWrapper} from '../global/common/LinkWrapper';
import {SpinnerInternal} from '../global/common/Loader';

import './SAProductStocks.scss';

export const SAProductStocks = () => {
  const [loading, setLoading] = useState(false);
  const [baseProducts, setBaseProducts] = useState([]);
  const [clubOwnerRequests, setClubOwnerRequests] = useState([]);

  const [filteredProductsData, setFilteredProductsData] = useState([]);

  const [teams, setTeams] = useState([defaultTeam]);
  const [colors, setColors] = useState(defaultColors);
  const [productType, setProductType] = useState([defaultProductType]);

  const [teamsFilterMobileOpen, setTeamsFilterMobileOpen] = useState(false);
  const [colorsFilterMobileOpen, setColorsFilterMobileOpen] = useState(false);
  const [productTypeFilterMobileOpen, setProductTypeFilterMobileOpen] =
    useState(false);

  const [search, setSearch] = useState('');

  const fetchData = async () => {
    setLoading(true);
    await fetchDataFromAPIEndpoint('/baseProducts').then((d) => {
      console.log(d);

      setBaseProducts(d.data.shopifyProductsList);
      const resFilterData = getFiltersFields(d.data.shopifyProductsList);
      setColors(resFilterData.colorData);
      setTeams(resFilterData.teamsData);
      setProductType(resFilterData.productTypeData);

      setFilteredProductsData(d.data.shopifyProductsList);

      setLoading(false);
    });
    fetchDataFromAPIEndpoint('/clubOwnerRequests').then((cbRequest) => {
      setClubOwnerRequests(cbRequest.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (clubOwnerRequests.length) {
      console.log(clubOwnerRequests);
    }
  }, [clubOwnerRequests]);

  // Filters

  const isTeamsFilterActive = teams.find(
    (t) => t.active === true && t.value !== defaultTeam[0].value
  );
  const isColorssFilterActive = colors.find(
    (t) => t.active === true && t.value !== defaultColors[0].value
  );
  const isPTypeFilterActive = productType.find(
    (t) => t.active === true && t.value !== defaultProductType[0].value
  );
  const isFilterActive =
    !!isTeamsFilterActive ||
    !!isColorssFilterActive ||
    !!isPTypeFilterActive ||
    !!search;

  const clearFilter = () => {
    setFilteredProductsData(baseProducts);
    setSearch('');
    setTeams((prev) =>
      prev.map((el, i) => {
        if (el.value === defaultTeam[0].value) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
    setColors((prev) =>
      prev.map((el, i) => {
        if (el.value === defaultColors[0].value) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
    setProductType((prev) =>
      prev.map((el, i) => {
        if (el.value === defaultProductType[0].value) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
  };

  const onFilterChange = (filterType, key) => {
    let filteredData = [];

    let currentTeam = getActiveFilterValue(teams);
    let currentColors = getActiveFilterValue(colors);
    let currentProductType = getActiveFilterValue(productType);
    let currentSearch = search;

    if (filterType === FILTER_NAMES.team) {
      currentTeam = teams[key];
    } else if (filterType === FILTER_NAMES.colors) {
      currentColors = colors[key];
    } else if (filterType === FILTER_NAMES.productType) {
      currentProductType = productType[key];
    } else if (filterType === 'search') {
      currentSearch = key;
    }

    // Team Filter
    filteredData = !currentTeam?.id
      ? baseProducts
      : baseProducts.filter((fpd) => {
          const teamFil = getTag(
            fpd.shopifyProduct,
            FILTER_NAMES.team,
            '-',
            'rightPart'
          )?.key;

          if (!!teamFil && currentTeam.id && teamFil === currentTeam.label) {
            return fpd;
          }
        });

    //Color Filter
    filteredData = !currentColors?.id
      ? filteredData
      : filteredData.filter((fpd) => {
          const colorFil = getTag(
            fpd.shopifyProduct,
            FILTER_NAMES.colors
          )?.value;
          console.log(colorFil);
          if (
            !!colorFil &&
            currentColors.id &&
            colorFil === currentColors.label
          ) {
            return fpd;
          }
        });

    // Product Type filter
    filteredData = !currentProductType?.id
      ? filteredData
      : filteredData.filter((fpd) => {
          const pTypeFil = fpd.shopifyProduct.product_type.toLowerCase().trim();
          if (
            !!pTypeFil &&
            currentProductType.id &&
            pTypeFil === currentProductType.value
          ) {
            return fpd;
          }
        });
    console.log(currentSearch);
    // search filter
    filteredData = !currentSearch
      ? filteredData
      : filteredData.filter((fpd) => {
          const title = fpd.shopifyProduct.title.toLowerCase().trim();
          console.log(title);
          if (title.indexOf(currentSearch) !== -1) {
            return fpd;
          }
        });

    setFilteredProductsData(filteredData);

    console.log(currentTeam, currentColors, currentProductType, currentSearch);
  };

  const onFilterTeamsChange = (key) => {
    console.log(teams[key]);

    setTeams((prev) =>
      prev.map((el, i) => {
        if (i === key) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
    onFilterChange(FILTER_NAMES.team, key);
  };
  const onFilterColorsChange = (key) => {
    console.log(colors[key]);

    setColors((prev) =>
      prev.map((el, i) => {
        if (i === key) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
    onFilterChange(FILTER_NAMES.colors, key);
  };
  const onFilterProductTypeChange = (key) => {
    console.log(key);

    setProductType((prev) =>
      prev.map((el, i) => {
        if (i === key) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      })
    );
    onFilterChange(FILTER_NAMES.productType, key);
  };
  const onSearch = (e) => {
    const val = e.target.value.trim();
    setSearch(e.target.value);
    onFilterChange('search', val);
  };
  // End Filters

  const QuantityInput = ({pVariant, bProduct, clubs}) => {
    const [val, setVal] = useState(pVariant.inventory_quantity);
    const [currentQty, setCurrentQty] = useState(pVariant.inventory_quantity);
    const [loading, setLoading] = useState(false);
    const listOfClubProductsShopifyId = [];

    clubs.forEach((c) => {
      c.clubProducts.forEach((cP) => {
        if (cP.product_id === bProduct._id) {
          listOfClubProductsShopifyId.push(cP.clubShopifyProductID);
        }
      });
    });

    const saveQuantity = (
      shopifyId,
      variantId,
      value,
      listOfClubProductsShopifyId,
      variantTitle
    ) => {
      setLoading(true);
      console.log(shopifyId, variantId, value, listOfClubProductsShopifyId);
      saveDataToAPIEndpoint('updateStock', {
        shopifyId,
        variantId,
        value,
        listOfClubProductsShopifyId,
        variantTitle
      }).then((res) => {
        console.log(res);
        setCurrentQty(res.data.inventory_level.available);
        setLoading(false);
      });
    };

    return (
      <>
        <div className="ProductStock__VariantQty">
          <span>Current QTY:</span>
          <b> {currentQty}</b>
        </div>
        <label>New QTY:</label>
        <input
          onChange={(e) => setVal(e.target.value)}
          type="number"
          id="qty"
          value={val}
          width={100}
        />
        {loading ? (
          <SpinnerInternal />
        ) : (
          <Button
            strechcontent
            onClick={() =>
              saveQuantity(
                bProduct.shopifyProduct.id,
                pVariant.inventory_item_id,
                val,
                listOfClubProductsShopifyId,
                pVariant.title
              )
            }
          >
            Save
          </Button>
        )}
      </>
    );
  };
  const getClubsBasedOnProductId = (product_id) => {
    const data = [];
    clubOwnerRequests.forEach((cR) => {
      cR.clubProducts.forEach((cP) => {
        if (cP.product_id === product_id && !!cP.clubShopifyProductID) {
          data.push(cR);
        }
      });
    });
    // console.log('getClubsBasedOnProductId: ', data);
    return data;
  };
  return (
    <div className="ProductStock">
      {loading ? (
        <SpinnerInternal accessibilityLabel="Spinner example" size="large" />
      ) : (
        <div className="ProductStock__BaseProducts">
          <div className="Filters">
            <div
              className={
                teamsFilterMobileOpen
                  ? ' Filters_Open Filters__types'
                  : 'Filters__types'
              }
            >
              <span
                className="Filters__name"
                onClick={() => setTeamsFilterMobileOpen(!teamsFilterMobileOpen)}
              >
                Kategorie:
              </span>
              {teams.map((t, key) => (
                <Button
                  key={key}
                  onClick={() => onFilterTeamsChange(key)}
                  type={t.active ? ButtonType.Primary : ButtonType.Secondary}
                  hoverDisabled={t.active}
                  width="112px"
                >
                  {t.label}
                </Button>
              ))}
            </div>

            <div
              className={
                colorsFilterMobileOpen
                  ? ' Filters_Open Filters__colors'
                  : 'Filters__colors'
              }
            >
              <span
                className="Filters__name"
                onClick={() =>
                  setColorsFilterMobileOpen(!colorsFilterMobileOpen)
                }
              >
                FARBE:
              </span>
              {colors.map((c, key) =>
                c.value === 'all' ? (
                  <Button
                    key={key}
                    onClick={() => onFilterColorsChange(key)}
                    type={c.active ? ButtonType.Primary : ButtonType.Secondary}
                    hoverDisabled
                    width="112px"
                  >
                    {c.label}
                  </Button>
                ) : (
                  <Button
                    key={key}
                    onClick={() => onFilterColorsChange(key)}
                    type={c.active ? ButtonType.Primary : ButtonType.Secondary}
                    hoverDisabled
                    width="112px"
                    borderColor={c.value}
                    fontColor={c.value}
                    backgroundColor={c.active && c.value}
                  >
                    {c.label}
                  </Button>
                )
              )}
            </div>

            <div
              className={
                productTypeFilterMobileOpen
                  ? ' Filters_Open Filters__product_tag'
                  : 'Filters__product_tag'
              }
            >
              <span
                className="Filters__name"
                onClick={() =>
                  setProductTypeFilterMobileOpen(!productTypeFilterMobileOpen)
                }
              >
                PRODUKT:
              </span>
              {productType.map((pt, key) => (
                <Button
                  key={key}
                  onClick={() => onFilterProductTypeChange(key)}
                  type={pt.active ? ButtonType.Primary : ButtonType.Secondary}
                  hoverDisabled={pt.active}
                  width="112px"
                >
                  {pt.label}
                </Button>
              ))}
            </div>

            <div className="FiltersSearch">
              <input
                name="search"
                type={'text'}
                placeholder="Suchen"
                onInput={onSearch}
                value={search}
              />
              <Icon className="search-icon" source={SearchMajor} color="base" />
            </div>
          </div>
          {isFilterActive && (
            <div className="Filters__clear">
              <LinkWrapper underline onClick={clearFilter}>
                Alle Filter zurücksetzen
              </LinkWrapper>
            </div>
          )}
          {!filteredProductsData.length && <div>No Data</div>}
          {filteredProductsData.map((bProduct) => (
            <div className="ProductStock__BaseProducts__Item">
              <div className="ProductStock__BaseProducts__ItemTitle">
                <div className="ProductStock__DetailWrapper">
                  <div className="ProductStock__DetailWrapper__Left">
                    <img
                      src={bProduct.shopifyProduct.images[0].src}
                      alt="shopify"
                      height={100}
                    />
                  </div>
                  <div className="ProductStock__DetailWrapper__Right">
                    <Text variant="headingMd">
                      <b> {bProduct.shopifyProduct.title}</b>
                    </Text>
                    <Text variant="headingSm">
                      {
                        getTag(bProduct.shopifyProduct, FILTER_NAMES.colors)
                          .value
                      }
                    </Text>
                    <b>Shopify ID:</b> {bProduct.shopifyProduct.id}
                    <br />
                    {/* Product ID: {bProduct._id} */}
                    <div>
                      <b>Club Lists: </b>

                      {!getClubsBasedOnProductId(bProduct._id).length &&
                        'No Club has this Product'}

                      {getClubsBasedOnProductId(bProduct._id)?.map((cR) => (
                        <span>{cR?.clubName + ', '}</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ProductStock__VariantWrapper">
                {bProduct.shopifyProduct.variants.map((pVariant) => (
                  <div className="ProductStock__Variant">
                    <div className="ProductStock__VariantTitle">
                      Variant: {pVariant.title}
                    </div>

                    <QuantityInput
                      pVariant={pVariant}
                      bProduct={bProduct}
                      clubs={getClubsBasedOnProductId(bProduct._id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
