import {SET_CSRF_TOKEN, SET_USER_PROFILE_DATA} from './types';

export const setCSFRTokenData = (data) => ({
  type: SET_CSRF_TOKEN,
  payload: data
});

export const setUserProfileData = (data) => ({
  type: SET_USER_PROFILE_DATA,
  payload: data
});
