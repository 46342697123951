import {Fragment, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {fetchDataFromAPIEndpoint} from './axios';
import {
  setBaseProductsData,
  setBaseProductsDataLoading
} from './club-owner/actions';
import {getOnlyProductsWithColors} from './club-owner/ProductsUtils';
import {Navigation} from './global/Navigation';
import {SubHeader} from './global/SubHeader';

export const Layout = (props) => {
  const dispatch = useDispatch();
  const fetchBaseProducts = async () => {
    dispatch(setBaseProductsDataLoading(true));
    const res = await fetchDataFromAPIEndpoint('/baseProducts');

    return res.data;
  };

  useEffect(() => {
    fetchBaseProducts().then((data) => {
      if (!data.shopifyProductsList.length) {
        return alert('problem in fetching data');
      }

      const finalData = getOnlyProductsWithColors(data.shopifyProductsList);

      dispatch(setBaseProductsData(finalData));
      dispatch(setBaseProductsDataLoading(false));
    });
  }, []);
  return (
    <Fragment>
      <Navigation />
      <SubHeader />
      <div className="App_content">
        <Outlet />
      </div>
    </Fragment>
  );
};
