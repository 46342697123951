import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {URLList} from '../App';
import {saveDataToAPIEndpoint, saveFileDataToAPIEndpoint} from '../axios';
import {textColorsList} from '../config';
import {Button} from '../global/common/Button';
import {ImageConfigPreview} from '../global/common/ImageConfigPreview';
import {Loader} from '../global/common/Loader';
import {SelectColor} from '../global/common/SelectColor';
import {setConfigData} from './actions';
import {getProductsDataFromCartData} from './Cart';
import './ClubAddNewProducts.scss';
import {DefaultImage} from './DefaultImage';
import {
  FILTER_NAMES,
  getColorFromGermanWord,
  getFilter,
  getTag
} from './ProductsUtils';
import {resetLocalStorage} from './reducers/cartReducer';

export const onClubAddNewProductSave = () => async (dispatch, getState) => {
  console.log('onClubAddNewProductSave');
  const cartData = getState().cart.data;
  const configData = getState().cart.config;
  const clubOwnerData = getState().products.clubOwnerData;

  dispatch(setConfigData({...configData, loading: true}));

  const clubProducts = cartData.map((c) => {
    return {product_id: c};
  });
  try {
    const data = {
      selectedColorsList: configData.selectedColorsList,
      colorsListBasedOnCart: configData.colorsListBasedOnCart,
      clubProducts: clubProducts
    };

    saveDataToAPIEndpoint(
      '/addNewClubProductsRequest/' + clubOwnerData._id,
      data
    ).then((res) => {
      console.log(res);
      if (res.status === 200) {
        resetLocalStorage();
        window.location.href = URLList.Success;
        // dispatch(setConfigData(initialConfigData));
        // dispatch(setCartData([]));
      }
    });
  } catch (err) {
    console.log('err in saving:', err);
    dispatch(setConfigData({...configData, loading: false}));
    window.location.href = URLList.ClubAddNewProducts;
  }
};

export const ClubAddNewProducts = () => {
  const dispatch = useDispatch();
  const [cartData, setCartData] = useState([]);
  const tmpCartData = useSelector((state) => state.cart.data) || [];
  const configData = useSelector((state) => state.cart.config) || [];
  const baseProducts = useSelector((state) => state.products.baseProducts);
  const clubOwnerData = useSelector((state) => state.products.clubOwnerData);
  const baseProductsLoading = useSelector(
    (state) => state.products.baseProductsLoading
  );

  const isColorAlreadySelected = (clr) => {
    return !!clubOwnerData?.colorsListBasedOnCart.find(
      (cl) => cl.color === clr
    );
  };
  useEffect(() => {
    let cLists = [];
    if (!!baseProducts.length) {
      const cartDataList = getProductsDataFromCartData(
        tmpCartData,
        baseProducts
      );
      setCartData(cartDataList);
      cartDataList?.map((cData) => {
        const colorFil = getTag(cData.shopifyProduct, FILTER_NAMES.colors);
        console.log(colorFil);
        if (
          colorFil &&
          !!colorFil?.value &&
          !isColorAlreadySelected(getColorFromGermanWord(colorFil.value))
        ) {
          cLists.push({
            id: colorFil.id,
            color: getColorFromGermanWord(colorFil.value),
            label: colorFil.value
          });
        }
      });
      const uniqueColorsData = [
        ...new Map(cLists.map((m) => [m.color, m])).values()
      ];
      const reloadData = (sColorList, cListBasedOnCart) => {
        const newData = [];
        cListBasedOnCart.forEach((clBasedonChart) => {
          const data = sColorList.find(
            (sC) => clBasedonChart.color === sC.cartColorID
          );
          if (data) {
            newData.push(data);
          }
        });
        return newData;
      };

      dispatch(
        setConfigData({
          ...configData,
          colorsListBasedOnCart: uniqueColorsData,
          selectedColorsList: reloadData(
            configData.selectedColorsList,
            uniqueColorsData
          )
        })
      );
    }
  }, [baseProducts]);

  const updateColorData = (cl, clBasedOnCart) => {
    let finalData = configData.selectedColorsList || [];
    finalData = [
      ...finalData,
      {
        cartColorID: clBasedOnCart.color,
        selectedTextColorID: cl.id
      }
    ];

    const uniqueColorsData = [
      ...new Map(finalData.map((m) => [m.cartColorID, m])).values()
    ];
    dispatch(
      setConfigData({
        ...configData,
        selectedColorsList: uniqueColorsData
      })
    );
  };

  const filterSameColor = (cList, color) =>
    cList.filter((cli) => cli.color !== color);

  const getSelectedColor = (cartColorID) => {
    const selectedColorData = configData?.selectedColorsList?.find(
      (sCL) => sCL.cartColorID === cartColorID
    );
    return textColorsList.find(
      (tCL) => tCL.id === selectedColorData?.selectedTextColorID
    );
  };
  const getFirstColorBasedOnCart = () =>
    configData.colorsListBasedOnCart.length &&
    configData.colorsListBasedOnCart[0];

  if (!!baseProductsLoading) {
    return <Loader />;
  }
  if (!configData.colorsListBasedOnCart.length) {
    return <>No Color Selection</>;
  }

  return cartData.length ? (
    <div className="club-config-add-new-products">
      <div className="club-config-add-new-products__right-part">
        {/* <div className="club-config-add-new-products__right-part__header">
          <span>
            VORSCHAU TEAM SHIRT{' '}
            {configData?.colorsListBasedOnCart.length &&
              configData.colorsListBasedOnCart[0].label}
          </span>
          <div className="form-field">
            <label htmlFor="ColorPreview">
              FARBE DES SCHRIFTZUGS AUSWÄHLEN*
            </label>
            <SelectColor
              id={'ColorPreview'}
              colorsList={filterSameColor(
                textColorsList,
                configData.colorsListBasedOnCart.length &&
                  configData.colorsListBasedOnCart[0].color
              )}
              selectedColor={getSelectedColor(
                getFirstColorBasedOnCart()?.color
              )}
              onColorSelect={(cl) =>
                updateColorData(cl, getFirstColorBasedOnCart())
              }
            />
          </div>
        </div>
        <ImageConfigPreview
          id={'Club-Owner-Config-Preview-'}
          frontImage={cartData[0]?.shopifyProduct?.images[0].src}
          backImage={cartData[0]?.shopifyProduct?.images[1].src}
          position={cartData[0]?.position}
          logoImage={configData.logo?.url}
          isClubAdmin={false}
          configData={{
            isClubNameShowInBack: configData.isClubNameShowInBack,
            firstLineText: configData.firstLineText,
            isCurved: configData.isCurved,
            secondLineText: configData.secondLineText,
            selectedColor: getSelectedColor(getFirstColorBasedOnCart()?.color)
          }}
        /> */}
        <div className="FurtherColorsSelection">
          <table cellSpacing="12">
            <tbody>
              <tr>
                <th>
                  <span>WEITERE FARBEN OBERTEIL*</span>
                </th>
                <th>
                  <span>FARBE DES SCHRIFTZUGS AUSWÄHLEN</span>
                </th>
              </tr>

              {configData?.colorsListBasedOnCart?.length &&
                configData.colorsListBasedOnCart.map((clBasedOnCart, key) => (
                  <tr key={key}>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          maxWidth: '120px'
                        }}
                      >
                        {clBasedOnCart.label}
                        <div
                          style={{
                            height: '20px',
                            width: '60px',
                            backgroundColor: clBasedOnCart.color,
                            border:
                              clBasedOnCart.color === 'white'
                                ? '1px solid black'
                                : ''
                          }}
                        ></div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <SelectColor
                          id={'ColorPreview'}
                          colorsList={filterSameColor(
                            textColorsList,
                            clBasedOnCart.color
                          )}
                          selectedColor={getSelectedColor(clBasedOnCart.color)}
                          onColorSelect={(cl) =>
                            updateColorData(cl, clBasedOnCart)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    <Link to={'/products'} className="products-button">
      Add Products
    </Link>
  );
};
