import axios from 'axios';
import {REACT_APP_BE_URL} from './config';
import store from './store';

const baseURL = REACT_APP_BE_URL;
const token = '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';
const token2 = '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';
const api_key = '4422493597a9894e41160b25ba803611';
const api_secret_key = '26111757e101379dc6d7c4ac4debd2fd';

const AXIOS_COMMON_HEADERS = [
  {
    name: 'Accept',
    value: 'multipart/form-data, application/json, text/plain, */*'
  },
  {name: 'Access-Control-Allow-Origin', value: '*'},

  {name: 'Content-Type', value: 'multipart/form-data'},
  //  {name: 'X-Shopify-Access-Token', value: token}
  {
    name: 'Access-Control-Allow-Headers',
    value: 'Origin, X-Requested-With, Content-Type, Accept, Set-Cookie, Cookie'
  }
];

const INTERCEPTED_METHODS = ['post', 'put', 'delete'];
export const csrfTokenInterceptor = (config) => {
  if (config.method && INTERCEPTED_METHODS.includes(config.method)) {
    const csrfToken = store.getState().user.csrfToken;
    console.log(config.headers);
    if (!config.headers) {
      config.headers = {
        'x-csrf-token': csrfToken ?? ''
      };
    } else {
      config.headers['x-csrf-token'] =
        csrfToken ?? config.headers['x-csrf-token'];
    }
  }

  return config;
};

export const axiosInstance = () => {
  const instance = axios.create({
    baseURL
  });

  instance.interceptors.request.use(csrfTokenInterceptor);
  AXIOS_COMMON_HEADERS.forEach(({name, value}) => {
    instance.defaults.headers.common[name] = value;
  });

  return instance;
};

export const fetchDataFromAPIEndpoint = async (
  endpointUrl,
  withCredentials = true
) => {
  const source = axios.CancelToken.source();
  const csrfToken = store.getState().user.csrfToken;
  console.log(csrfToken);
  let config = {
    headers: {
      Authorization: 'Bearer ' + csrfToken
    }
  };
  try {
    return await axiosInstance().get(endpointUrl, config);
  } catch (error) {
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    }
  }
  return;
};

export const saveDataToAPIEndpoint = async (endpointUrl, data, id) => {
  const source = axios.CancelToken.source();
  try {
    if (!!id) {
      endpointUrl = endpointUrl + '/' + id;
    }

    return await axiosInstance().post(endpointUrl, data, {
      cancelToken: source.token
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    }
  }
  return;
};

export const saveFileDataToAPIEndpoint = async (endpointUrl, data, id) => {
  const source = axios.CancelToken.source();
  try {
    if (!!id) {
      endpointUrl = endpointUrl + '/' + id;
    }

    return await axiosInstance().post(endpointUrl, data, {
      headers: {'Content-Type': 'multipart/form-data'},
      cancelToken: source.token
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    }
  }
  return;
};

export const deleteDataFromAPIEndpoint = async (endpointUrl, FormData) => {
  const source = axios.CancelToken.source();
  try {
    return await axiosInstance().delete(endpointUrl, {
      withCredentials: true,
      cancelToken: source.token,
      data: FormData
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    }
  }
  return;
};

export const updateDataToAPIEndpoint = async (
  endpointUrl,
  data,
  withCredentials = true
) => {
  const source = axios.CancelToken.source();
  try {
    return await axiosInstance().put(endpointUrl, data, {
      withCredentials,
      cancelToken: source.token
    });
  } catch (error) {
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    }
  }
  return;
};
