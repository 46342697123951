import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {deleteDataFromAPIEndpoint, fetchDataFromAPIEndpoint} from '../axios';
import {Button} from '../global/common/Button';
import {setClubOwnerData} from './actions';
import './ClubDashboard.scss';
import {SpinnerInternal} from '../global/common/Loader';
import {Text} from '@shopify/polaris';

export const ClubDashboard = () => {
  const profileData = useSelector((state) => state.user.profileData);
  const clubOwnerData = useSelector((state) => state.products.clubOwnerData);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchClubData = () => {
    fetchDataFromAPIEndpoint(
      '/clubDataBasedOnUser/' + profileData.club_id
    ).then((cbRequest) => {
      dispatch(setClubOwnerData(cbRequest.data));
    });
  };

  useEffect(() => {
    if (!!profileData.club_id) {
      fetchClubData();
    } else {
      navigate('/');
    }
  }, [profileData]);

  const onDeleteProduct = (clubShopifyProductID) => {
    if (
      window.confirm(
        'Are you sure? \n This action will delete the product from shopify too.'
      )
    ) {
      setIsLoading(true);
      deleteDataFromAPIEndpoint(
        '/deleteClubProduct/' + clubShopifyProductID + '/' + profileData.club_id
      ).then((res) => {
        if (res.data === 'okay') {
          fetchClubData();
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <div className="ClubDashboard">
      <Text variant="headingXl">Club Dashboard</Text>
      <div className="cart">
        {isLoading ? (
          <SpinnerInternal />
        ) : (
          clubOwnerData.clubProducts?.map((c, key) => (
            <div className="cart__item" key={key}>
              <div className="cart__image">
                <img
                  height={100}
                  id={c.localProductData._id}
                  src={c.shopifyProductData.images[0].src}
                  alt={'product'}
                />
              </div>
              <Button
                onClick={() =>
                  onDeleteProduct(c.localProductData.clubShopifyProductID)
                }
                className="products-button"
                underline
              >
                ENTFERNEN
              </Button>
              <div className="products-title">{c.shopifyProductData.title}</div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
