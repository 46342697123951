import {Frame, Loading, Spinner} from '@shopify/polaris';

export const Loader = () => (
  <div style={{height: '100px'}}>
    <Frame>
      <Loading />
    </Frame>
  </div>
);

export const SpinnerInternal = () => {
  return <Spinner accessibilityLabel="Spinner example" size="large" />;
};
