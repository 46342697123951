import {Fragment} from 'react';
import {Outlet} from 'react-router-dom';
import {AdminNavigation} from './global/AdminNavigation';

export const AdminLayout = (props) => {
  return (
    <Fragment>
      <AdminNavigation />
      <div className="App_content Admin">
        <Outlet />
      </div>
    </Fragment>
  );
};
