import {Button, Text} from '@shopify/polaris';
import CircleType from 'circletype';
import html2canvas from 'html2canvas';
import React, {useEffect, useState} from 'react';
import {calculateFontSize, getLogoStyles} from '../ImageLogoAndTextPosition';
import './ImageConfigPreview.scss';
import defaultLogo from './../../preview-logo.png';
import {textColorsList} from '../../config';
import {SelectColor} from './SelectColor';

let circleTypeObject = '';
// Show on club admin site that how the logo and text would look
export const ImageConfigPreview = React.memo(
  ({
    frontImage,
    backImage,
    thirdImage,
    forthImage,
    logoImage,
    isClubAdmin = false,
    position = null,
    id,
    configData,
    defaultColor
  }) => {
    // Image position code Start
    const frontImageWrapperId = id + 'firstImageFront';
    const frontImageId = id + 'firstImageFrontImage';
    const frontImageLogoWrapperId = id + 'firstImageFrontLogo';
    const frontImageLogoId = id + 'logoImagePNG';

    const secondImageWrapperId = id + 'secondImageBack';
    const secondImageId = id + 'secondImageBackImage';
    const secondImageTextId = id + 'secondImageBackText';
    const secondImageFirstLineTextId = id + 'firstline';
    const secondImageSecondLineTextId = id + 'secondline';

    const thirdImageWrapperId = id + 'thirdImageFront';
    const thirdImageId = id + 'thirdImageFrontImage';
    const thirdImageLogoWrapperId = id + 'thirdImageFrontLogo';
    const thirdImageLogoId = id + 'thirdlogoImagePNG';

    const fourthImageWrapperId = id + 'fourthImageFront';
    const fourthImageId = id + 'fourthImageFrontImage';
    const fourthImageLogoWrapperId = id + 'fourthImageFrontLogo';
    const fourthImageLogoId = id + 'fourthlogoImagePNG';

    const [logoSizes, setLogoSizes] = useState({
      height: (position?.LogoSize || 100) + 'px',
      width: 'auto'
    });
    const [logoThirdImageSizes, setLogoThirdImageSizes] = useState({
      height: (position?.LogoThirdImageSize || 100) + 'px',
      width: 'auto'
    });
    const [logoFourthImageSizes, setLogoFourthImageSizes] = useState({
      height: (position?.LogoFourthImageSizes || 100) + 'px',
      width: 'auto'
    });

    const [textSize, setTextSize] = useState(
      calculateFontSize(position.SecondLineTextSize, configData.firstLineText)
    );
    const [textLineHeight, setTextLineHeight] = useState(undefined);
    const [textColor, setTextColor] = useState({
      color: configData.selectedColor?.color
    });

    useEffect(() => {
      const cl = configData.selectedColor?.color || defaultColor;
      const clfinal = textColorsList.find((tcl) => tcl.color === cl);
      setTextColor(clfinal);
    }, [configData.selectedColor?.color, defaultColor]);

    const dragElement = (elmnt, conatainer) => {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      const dragMouseDown = (e) => {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      };
      const theTextEle = elmnt;
      if (theTextEle) {
        /* if present, the header is where you move the DIV from:*/
        theTextEle.onmousedown = dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }

      const elementDrag = (e) => {
        // calculate the new cursor position:
        let pOffsetBottom =
          elmnt.parentNode.offsetTop + elmnt.parentNode.offsetHeight - 1;
        let offsetRight =
          elmnt.parentNode.offsetLeft + elmnt.parentNode.offsetWidth - 1;
        //console.log(pOffsetBottom, offsetRight);

        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;

        elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';

        const draw = document.getElementById(conatainer);

        if (draw) {
          var offsetX =
            e.clientX - parseInt(window.getComputedStyle(elmnt).left);
          var offsetY =
            e.clientY - parseInt(window.getComputedStyle(elmnt).top);

          let top = e.clientY - offsetY;
          let left = e.clientX - offsetX;

          if (top < 0) top = 0;
          if (left < 0) left = 0;
          if (top > draw.clientHeight - elmnt.clientHeight)
            top = draw.clientHeight - elmnt.clientHeight;
          if (left > draw.clientWidth - elmnt.clientWidth)
            left = draw.clientWidth - elmnt.clientWidth;

          elmnt.style.top = top + 'px';
          elmnt.style.left = left + 'px';
        }
      };

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    };
    useEffect(() => {
      if (isClubAdmin) {
        dragElement(
          document.getElementById(frontImageLogoWrapperId),
          frontImageWrapperId
        );
        dragElement(
          document.getElementById(secondImageTextId),
          secondImageWrapperId
        );
        dragElement(
          document.getElementById(thirdImageLogoWrapperId),
          thirdImageWrapperId
        );
        dragElement(
          document.getElementById(fourthImageLogoWrapperId),
          fourthImageWrapperId
        );
      }
    }, []);

    const setLogoSizesOnChange = async () => {
      setLogoSizes(await getLogoSize(position.LogoSize || 100));
      setLogoThirdImageSizes(
        await getLogoSize(position.LogoThirdImageSize || 100)
      );
      setLogoFourthImageSizes(
        await getLogoSize(position.LogoFourthImageSize || 100)
      );
    };

    useEffect(() => {
      if (logoImage) {
        setLogoSizesOnChange();
      }
    }, [logoImage]);

    useEffect(() => {
      if (configData.isCurved) {
        circleTypeObject = new CircleType(
          document.getElementById(secondImageFirstLineTextId)
        );
        circleTypeObject.radius(90);
        circleTypeObject.forceHeight(false);
        circleTypeObject.forceWidth(false);
      } else {
        if (!!circleTypeObject) {
          circleTypeObject.destroy();
          document
            .getElementById(secondImageFirstLineTextId)
            .replaceChildren(getFirstLineText());
        }
      }
    }, [configData.isCurved]);

    const getLogoSize = async (size) => {
      let data = logoSizes;
      let img = new Image();
      img.src = logoImage;
      await img.decode();
      let width = img.width;
      let height = img.height;

      // if width is higher
      if (width > height) {
        data = {width: size + 'px', height: 'auto'};
      } else {
        data = {height: size + 'px', width: 'auto'};
      }
      return data;
    };
    const getFirstLineText = () => {
      return configData.firstLineText
        ? configData.firstLineText
        : !isClubAdmin
        ? !configData.firstLineText && !configData.secondLineText
          ? 'TEXT HINZUFÜGEN'
          : ''
        : '';
    };

    const logoSrc = logoImage || defaultLogo;
    return (
      <div className="ImageConfigPreview">
        {frontImage && (
          <div>
            {isClubAdmin && (
              <div className="logoSizeField">
                <label htmlFor="logosize">First Image Logo size: </label>
                <input
                  id="logosize"
                  type={'number'}
                  onChange={(e) =>
                    setLogoSizes(
                      logoSizes.height === 'auto'
                        ? {height: 'auto', width: e.target.value + 'px'}
                        : {width: 'auto', height: e.target.value + 'px'}
                    )
                  }
                  value={
                    logoSizes.height === 'auto'
                      ? logoSizes?.width?.replace('px', '')
                      : logoSizes?.height?.replace('px', '')
                  }
                />
              </div>
            )}
            {/* first Image */}

            <div className="ImageConfigPreview__FirstImage">
              <div
                className="ImageConfigPreview__FirstImageWrapper"
                id={frontImageWrapperId}
              >
                <img
                  src={frontImage}
                  id={frontImageId}
                  className="ImageConfigPreview__FirstImage_Image"
                  alt=""
                  crossOrigin="anonymous"
                  height={500}
                />

                <div
                  id={frontImageLogoWrapperId}
                  className="ImageConfigPreview__FirstImage_LogoWrapper"
                  // onMouseDown='this.style.border = "dashed 2px #FF7F27";'
                  style={getLogoStyles(position, 1)}
                >
                  <img
                    key={logoImage}
                    id={frontImageLogoId}
                    className={'ImageConfigPreview__FirstImage_Logo'}
                    alt="test"
                    style={logoSizes}
                    src={logoSrc}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* second Image */}

        {!!thirdImage && (
          <div>
            {isClubAdmin && (
              <div className="logoSizeField">
                <label htmlFor="thirdimagelogosize">
                  Third Image Logo size:{' '}
                </label>
                <input
                  id="thirdimagelogosize"
                  type={'number'}
                  onChange={(e) =>
                    setLogoThirdImageSizes(
                      logoThirdImageSizes.height === 'auto'
                        ? {height: 'auto', width: e.target.value + 'px'}
                        : {width: 'auto', height: e.target.value + 'px'}
                    )
                  }
                  value={
                    logoThirdImageSizes.height === 'auto'
                      ? logoThirdImageSizes.width.replace('px', '')
                      : logoThirdImageSizes.height.replace('px', '')
                  }
                />
              </div>
            )}
            <div className="ImageConfigPreview__FirstImage">
              <div
                className="ImageConfigPreview__FirstImageWrapper"
                id={thirdImageWrapperId}
              >
                <img
                  src={thirdImage}
                  id={thirdImageId}
                  className="ImageConfigPreview__FirstImage_Image"
                  alt=""
                  crossOrigin="anonymous"
                  height={500}
                />

                <div
                  id={thirdImageLogoWrapperId}
                  className="ImageConfigPreview__FirstImage_LogoWrapper"
                  // onMouseDown='this.style.border = "dashed 2px #FF7F27";'
                  style={getLogoStyles(position, 3)}
                >
                  <img
                    key={logoImage}
                    id={thirdImageLogoId}
                    className={'ImageConfigPreview__FirstImage_Logo'}
                    alt="test"
                    style={logoThirdImageSizes}
                    src={logoSrc}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Third Image */}
        <div>
          {isClubAdmin && (
            <div className="logoSizeField min-height">
              <div>
                <label htmlFor="text-input-color">Text color: </label>
                <SelectColor
                  style={{maxWidth: '380px'}}
                  id={'ColorPreview'}
                  colorsList={textColorsList}
                  selectedColor={textColor}
                  onColorSelect={(cl) => {
                    setTextColor(cl);
                  }}
                />
              </div>
              <div>
                <label htmlFor="text-input-size-firstline">
                  Text Size: &nbsp;&nbsp;&nbsp;
                </label>
                <input
                  onChange={(e) => setTextSize(e.target.value)}
                  type="number"
                  id="text-input-size-firstline"
                  value={textSize}
                />
              </div>
              <div>
                <label htmlFor="text-input-size-firstline">Line Height: </label>
                <input
                  onChange={(e) => setTextLineHeight(e.target.value)}
                  type="number"
                  id="text-input-line-height-firstline"
                  value={textLineHeight}
                />
              </div>
            </div>
          )}
          <div className="ImageConfigPreview__SecondImage">
            <div
              className="ImageConfigPreview__SecondImageWrapper"
              id={secondImageWrapperId}
            >
              <img
                src={backImage}
                id={secondImageId}
                className="ImageConfigPreview__SecondImage_Image"
                alt=""
                crossOrigin="anonymous"
              />

              <div
                id={secondImageTextId}
                className={`ImageConfigPreview__SecondImage_TextWrapper ${textColor} ${defaultColor} `}
                style={{
                  color: textColor.color,
                  top: position?.TextTop || 0,
                  left: position?.TextLeft || 0,
                  width: position?.TextBoxWidth + 'px'
                }}
              >
                <span
                  key={configData.firstLineText}
                  id={secondImageFirstLineTextId}
                  className="ImageConfigPreview__SecondImage_FirstLine"
                  style={{
                    fontSize: textSize + 'px',
                    lineHeight: textLineHeight + 'px'
                  }}
                >
                  {getFirstLineText()}
                </span>
                <br />
                <div
                  id={secondImageSecondLineTextId}
                  className="ImageConfigPreview__SecondImage_FirstLine"
                  style={{
                    marginTop: configData.isCurved
                      ? position.SpaceBetweenCurvedText + 'px'
                      : position.SpaceBetweenText + 'px',
                    fontSize: textSize + 'px',
                    lineHeight: textLineHeight + 'px'
                  }}
                >
                  {configData.secondLineText}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Fourth Image */}
        {!!forthImage && (
          <div>
            {isClubAdmin && (
              <div className="logoSizeField min-height">
                <label htmlFor="fourthlogosize">Fourth Image Logo size: </label>
                <input
                  id="fourthlogosize"
                  type={'number'}
                  onChange={(e) =>
                    setLogoFourthImageSizes(
                      logoFourthImageSizes.height === 'auto'
                        ? {height: 'auto', width: e.target.value + 'px'}
                        : {width: 'auto', height: e.target.value + 'px'}
                    )
                  }
                  value={
                    logoFourthImageSizes.height === 'auto'
                      ? logoFourthImageSizes.width.replace('px', '')
                      : logoFourthImageSizes.height.replace('px', '')
                  }
                />
              </div>
            )}
            <div className="ImageConfigPreview__FirstImage">
              <div
                className="ImageConfigPreview__FirstImageWrapper"
                id={fourthImageWrapperId}
              >
                <img
                  src={forthImage}
                  id={fourthImageId}
                  className="ImageConfigPreview__FirstImage_Image"
                  alt=""
                  crossOrigin="anonymous"
                  height={500}
                />

                <div
                  id={fourthImageLogoWrapperId}
                  className="ImageConfigPreview__FirstImage_LogoWrapper"
                  // onMouseDown='this.style.border = "dashed 2px #FF7F27";'
                  style={getLogoStyles(position, 4)}
                >
                  <img
                    key={logoImage}
                    id={fourthImageLogoId}
                    className={'ImageConfigPreview__FirstImage_Logo'}
                    alt="test"
                    style={logoFourthImageSizes}
                    src={logoSrc}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);
