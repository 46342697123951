import {SET_CSRF_TOKEN, SET_USER_PROFILE_DATA} from '../actions/types';

export const initialState = {
  csrfToken: localStorage.getItem('csrfToken'),
  profileData: null
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CSRF_TOKEN: {
      localStorage.setItem('csrfToken', action.payload);
      return {
        ...state,
        csrfToken: action.payload
      };
    }
    case SET_USER_PROFILE_DATA: {
      return {
        ...state,
        profileData: action.payload
      };
    }

    default: {
      return {...state};
    }
  }
};
