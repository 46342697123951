import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {fetchDataFromAPIEndpoint} from '../axios';
import {Button, ButtonType} from '../global/common/Button';
import {LinkWrapper} from '../global/common/LinkWrapper';
import {Loader} from '../global/common/Loader';
import {setCartData} from './actions';
import './ProductsDetails.scss';

export const ProductsDetails = () => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState();
  const cartData = useSelector((state) => state.cart.data) || [];
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    fetchDataFromAPIEndpoint('/baseProducts/' + params.id).then((d) => {
      console.log(d.data);
      setProduct(d.data);
      setLoading(false);
    });
  }, []);

  const getImage = (p, isHover) => {
    if (!p.shopifyProduct.images.length) {
    }
    // console.log(p.shopifyProduct);
    return (
      (p.shopifyProduct.images.length
        ? p.shopifyProduct.images.length === 1
          ? p.shopifyProduct.images[0].src
          : p.shopifyProduct.images[isHover ? 1 : 0].src
        : p.shopifyProduct.image?.src) ||
      'frontend/src/super-admin/JL100072_d366d03e-3270-486a-a38f-5d4876e45c06_1024x1024.webp'
    );
  };

  const isProductInCart = (p) => cartData.find((cd) => cd === p._id);

  const onAddProduct = (p) => {
    dispatch(setCartData([...cartData, p._id]));
  };
  const onRemoveProduct = (p) => {
    const newdata = [];
    cartData.forEach((d) => {
      if (d !== p._id) {
        newdata.push(d._id);
      }
    });
    //newdata.splice(newdata.indexOf(p._id), 1);

    dispatch(setCartData(newdata));
  };
  if (!!loading) {
    return <Loader />;
  }

  return (
    <div>
      {product ? (
        <div className="SingleProduct">
          <div className="SingleProduct__LeftPart">
            <div className={'SingleProduct__Images'}>
              {product.shopifyProduct.images.map((img, key) => (
                <img
                  className="SingleProduct__Image"
                  src={img.src}
                  alt={'product'}
                  key={key}
                />
              ))}
            </div>
          </div>
          <div className="SingleProduct__RightPart">
            <div className="SingleProduct__RightPart__Title">
              {product.shopifyProduct.title}
            </div>
            <div className="SingleProduct__RightPart__Price">
              {product.shopifyProduct.variants.length
                ? product.shopifyProduct.variants[0].price + '€'
                : 'keine Angabe'}
            </div>
            {product.shopifyProduct.options.map((option, key) => (
              <div key={key} className="SingleProduct__RightPart__Options">
                <div className="SingleProduct__RightPart__OptionHeading">
                  {option.name === 'Größe' ? 'Verfügbare Größen' : option.name}
                </div>
                <div className="SingleProduct__RightPart__OptionValuesWrapper">
                  {option.values.map((val, key) => (
                    <div
                      key={key}
                      className="SingleProduct__RightPart__OptionValues"
                    >
                      {val}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="SingleProduct__RightPart__DescriptionHeading">
              Beschreibung:
            </div>
            <div
              className="SingleProduct__RightPart__Description"
              dangerouslySetInnerHTML={{
                __html: product.shopifyProduct.body_html
              }}
            />

            {isProductInCart(product) ? (
              <Button
                onClick={() => onRemoveProduct(product)}
                className="SingleProduct__RightPart__Button"
              >
                ENTFERNEN
              </Button>
            ) : (
              <Button
                onClick={() => onAddProduct(product)}
                className="SingleProduct__RightPart__Button"
              >
                HINZUFÜGEN
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>no product </div>
      )}
    </div>
  );
};
