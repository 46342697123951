import {productColorMapping} from './../config';
export const FILTER_NAMES = {
  colors: 'FarbFilter',
  team: 'team',
  productType: 'product_type'
};

export const defaultColors = [{label: 'Alle', value: 'all', active: true}];
export const defaultTeam = [{label: 'Alle', value: 'all', active: true}];
export const defaultProductType = [{label: 'Alle', value: 'all', active: true}];

export const getColorFromGermanWord = (germanWord) => {
  let color = 'black';

  productColorMapping.forEach((pCM) => {
    if (
      pCM.germanWord.trim().toLocaleLowerCase() ===
      germanWord.trim().toLocaleLowerCase()
    ) {
      color = pCM.color;
    }
  });
  return color;
};

export const getTag = (
  singleProduct,
  filterName,
  splitSymbol = ':',
  compare = 'leftPart'
) => {
  let tagData = false;

  singleProduct?.tags.split(',')?.forEach((tag) => {
    const key = tag.split(splitSymbol)[0].trim();
    const value = tag.split(splitSymbol)[1];

    if (
      (compare === 'leftPart' ? key.toLowerCase() : value?.toLowerCase()) ===
      filterName.trim().toLowerCase()
    ) {
      tagData = {key, value};
    }
  });

  return tagData;
};

export const getOnlyProductsWithColors = (products) => {
  return products.filter(
    (p) => !!getTag(p.shopifyProduct, FILTER_NAMES.colors)
  );
};

//todo: remove this
export const getFilter = (singleProduct, filterName) => {
  return singleProduct?.metafields?.length
    ? singleProduct?.metafields.find((metaF) => metaF.key === filterName)
    : false;
};
export const getImage = (p, isHover) => {
  if (!p.shopifyProduct?.images.length) {
  }
  // console.log(p.shopifyProduct);
  return (
    (p.shopifyProduct?.images.length
      ? p.shopifyProduct.images.length === 1
        ? p.shopifyProduct.images[0].src
        : p.shopifyProduct.images[isHover ? 1 : 0].src
      : p.shopifyProduct?.image?.src) ||
    'frontend/src/super-admin/JL100072_d366d03e-3270-486a-a38f-5d4876e45c06_1024x1024.webp'
  );
};

export const onImageMouseOver = (id) => {
  document.getElementById(id).style.display = 'none';
  document.getElementById(id + 'hover').style.display = 'block';
};
export const onImageMouseLeave = (id) => {
  document.getElementById(id).style.display = 'block';
  document.getElementById(id + 'hover').style.display = 'none';
};

export const getActiveFilterValue = (val) => val.find((t) => t.active === true);
