import {
  CART_SET_DATA,
  CART_SET_CONFIG,
  SET_CLUB_OWNER_DATA,
  SET_BASE_PRODUCTS,
  SET_BASE_PRODUCTS_LOADING
} from './types';

export const setCartData = (data) => ({
  type: CART_SET_DATA,
  payload: data
});

export const setConfigData = (data) => ({
  type: CART_SET_CONFIG,
  payload: data
});

export const setBaseProductsData = (data) => ({
  type: SET_BASE_PRODUCTS,
  payload: data
});

export const setBaseProductsDataLoading = (data) => ({
  type: SET_BASE_PRODUCTS_LOADING,
  payload: data
});

export const setClubOwnerData = (data) => ({
  type: SET_CLUB_OWNER_DATA,
  payload: data
});
