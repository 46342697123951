import {useNavigate} from 'react-router-dom';
import './Button.scss';
export const ButtonType = {
  Primary: 'Primary',
  Secondary: 'Secondary'
};

export const Button = ({
  onClick,
  type,
  hoverDisabled = false,
  underline = false,
  className = '',
  disabled = false,
  children,
  to = '',
  strechcontent = false,
  backgroundColor = '',
  minWidth = '',
  width = '',
  minHeight = '',
  borderColor,
  fontColor
}) => {
  const navigate = useNavigate();
  const onClickButton = (e) => {
    if (!!to) {
      navigate(to);
    } else {
      onClick(e);
    }
  };

  return (
    <div className={`Button ${className} ${disabled ? 'disabled' : ''}`}>
      <button
        disabled={disabled}
        className={[
          type === ButtonType.Secondary ? ' secondary ' : '',
          hoverDisabled ? '' : ' hover ',
          underline ? ' underline ' : ' ',
          strechcontent ? ' strechcontent ' : '',
          backgroundColor ? ' backgroundColor ' + backgroundColor + ' ' : ' '
        ]}
        onClick={onClickButton}
        style={{
          minWidth: minWidth,
          width: width,
          padding: width && 0,
          minHeight: minHeight,
          borderColor: borderColor === 'white' ? '#B8B8B8' : borderColor,
          color:
            fontColor === 'white' && !backgroundColor
              ? '#B8B8B8'
              : type === ButtonType.Secondary
              ? fontColor
              : ''
        }}
      >
        {children}
      </button>
    </div>
  );
};
