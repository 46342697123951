import {NavLink} from 'react-router-dom';
import './LinkWrapper.scss';

export const LinkWrapper = ({
  to,
  underline = false,
  className = '',
  onClick,
  children
}) => {
  return (
    <div
      className={`LinkWrapper ${className} ${underline ? 'underline ' : ''}`}
      onClick={onClick}
    >
      <NavLink to={to}>{children}</NavLink>
    </div>
  );
};
